import React, { useState, useEffect, useRef } from "react";
import "./Create.css"; // Make sure the path is correct

interface Tag {
  id: number;
  name: string;
}

interface TagPickerProps {
  availableTags: Tag[];
  selectedTags: Tag[];
  onChange: (tags: Tag[]) => void;
}

const TagPicker: React.FC<TagPickerProps> = ({
  availableTags,
  selectedTags,
  onChange,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Reference to the dropdown

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const handleTagClick = (tag: Tag) => {
    if (selectedTags.find((t) => t.id === tag.id)) {
      removeTag(tag);
    } else {
      onChange([...selectedTags, tag]);
    }
  };

  const removeTag = (tag: Tag) => {
    onChange(selectedTags.filter((t) => t.id !== tag.id));
  };

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  return (
    <div className="tagPickerContainer" ref={dropdownRef}>
      {selectedTags.length > 0 && (
        <div className="selectedTags">
          {selectedTags.map((tag) => (
            <div key={tag.id} className="tag">
              {tag.name}
              <button
                onClick={() => removeTag(tag)}
                className="removeTagButton"
              >
                X
              </button>
            </div>
          ))}
        </div>
      )}

      <div className="selectTags" onClick={toggleDropdown}>
        Select
        <span className="dropdownIcon">{dropdownVisible ? "▲" : "▼"}</span>
      </div>
      {dropdownVisible && (
        <div className="dropdownContent">
          {availableTags.map((tag) => (
            <div
              key={tag.id}
              onClick={() => handleTagClick(tag)}
              className={`dropdownItem ${
                selectedTags.find((t) => t.id === tag.id)
                  ? "selected"
                  : "unselected"
              }`}
            >
              {tag.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TagPicker;
