import React, {
  useState,
  useRef,
  useEffect,
  RefObject,
  useContext,
  useCallback,
} from "react";
import {
  useNavigate,
  useLocation,
  NavigateFunction,
  Location,
  useParams,
} from "react-router-dom";
// import { Character, PlatformContext } from "../../App";
import { useAuth0 } from "@auth0/auth0-react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import useRequest from "../../apis/useRequest";
// import { UserAssets } from "../../App";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Character, UserAssets } from "../../types";
import "./Chat.css";
import ReactGA from "react-ga4";
import SharePopup from "./SharePopup";
import SignUp from "../Auth/SignUp";
import { CircularProgress } from "@mui/material";
import { Alert, Snackbar } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import SendIcon from "@mui/icons-material/Send";
import useWebSocket from "react-use-websocket";
import {
  isDarkColor,
  formatMessageText,
  reverseFormatMessageText,
} from "./utils";
import {
  getChatMessages,
  updateMessage,
  deleteMessage,
  getUserAssets,
  getCharacter,
} from "../../apis/request";
import { v4 as uuidv4 } from "uuid";
interface Message {
  sender: "user" | "char";
  text: string;
  message_id?: string;
  message_timestamp: string;
}

interface CharacterColors {
  [key: string]: {
    [key: string]: string;
  };
}

export interface PromptStyle {
  role: string;
  content: string;
}

function ChatMode() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState<string>("");
  const [isChatStart, setIsChatStart] = useState<boolean>(true);
  const [modelContext, setModelContext] = useState<PromptStyle[]>([]);
  const [sessionId, setSessionId] = useState<string>("");
  const [showTyping, setShowTyping] = useState<boolean>(false);
  const [chatId, setChatId] = useState<string>("");
  const [username, setUsername] = useState<string>("N/A");
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [userIsCreator, setUserIsCreator] = useState<boolean>(false);
  const [characterLoading, setCharacterLoading] = useState<boolean>(true);
  const [editingMessageIndex, setEditingMessageIndex] = useState<number>(-1);
  const [editingMessageText, setEditingMessageText] = useState<string>("");
  const [senderBeingEdited, setSenderBeingEdited] = useState<string>("");
  const [isCharMessageDark, setIsCharMessageDark] = useState<boolean>(false);
  const [deleteOperationPerfomed, setDeleteOperationPerformed] =
    useState<boolean>(false);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [blockInput, setBlockInput] = useState<boolean>(false);
  const [websocketUrl, setWebsocketUrl] = useState<string | null>(null);
  const [chatPaused, setChatPaused] = useState<boolean>(false);
  const isMobile = window.innerWidth <= 768; // Simple mobile detection based on screen width

  const [activeButtons, setActiveButtons] = useState<{ [key: number]: string }>(
    {}
  );
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [chatError, setChatError] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  // Helper function to open alert
  const showAlert = (message: string, severity: "success" | "error") => {
    setAlertInfo({ open: true, message, severity });
  };

  // Helper function to close alert
  const closeAlert = () => {
    setAlertInfo({ ...alertInfo, open: false });
  };

  const [character, setCharacter] = useState<Character>({
    character_id: "",
    name: "",
    image_url: "",
    creator: "",
    bio: "",
    char_message_bg_color: "",
    user_message_bg_color: "",
    char_text_color: "",
    user_text_color: "",
    is_public: true,
    chat_background_color: "",
    chat_background_url: "",
    interactions: 0,
    num_saves: 0,
    created_at: "",
  });

  const { character_id, chat_id } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const makeRequest = useRequest();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { darkMode, setDarkMode, userAssets, setUserAssets } = context;
  const getUtcTimestamp = (): string => {
    return new Date().toISOString();
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      const url = `${
        process.env.REACT_APP_4WALL_WEBSOCKET_API
      }?username=${encodeURIComponent(user["https://chat.4wall.ai/username"])}`;
      setWebsocketUrl(url);
    } else {
      setWebsocketUrl(null); // Ensure WebSocket is disconnected if user is not authenticated
    }
  }, [isAuthenticated, user]);

  const parseCharacterResponse = (response: any): Character => {
    return {
      character_id: response.character_id,
      name: response.name,
      image_url: response.image_url,
      creator: response.creator,
      bio: response.bio,
      char_message_bg_color: response.char_message_bg_color,
      user_message_bg_color: response.user_message_bg_color,
      char_text_color: response.char_text_color,
      user_text_color: response.user_text_color,
      is_public: response.is_public,
      chat_background_color: response.chat_background_color,
      chat_background_url: response.chat_background_url,
      interactions: response.interactions,
      num_saves: response.num_favorites,
      created_at: response.created_at,
    };
  };
  const fetchRemoteCharacterInfo = () => {
    (async () => {
      const userRequest = getCharacter(character_id || "");
      const response = await makeRequest<Character, void>(userRequest);
      const character_data = parseCharacterResponse(response);
      setCharacter(character_data);
      setCharacterLoading(false);
      let greeting = response.greeting || "";
      if (user) {
        const defaultPersona = userAssets.personas.find(
          (persona) => persona.default
        );
        const nameToReplaceWith = defaultPersona
          ? defaultPersona.name
          : user["https://chat.4wall.ai/username"];
        greeting = greeting.replaceAll("{{user}}", nameToReplaceWith);
        greeting = greeting.replaceAll("{{character}}", response.name);
        greeting = greeting.replaceAll("{{char}}", response.name);
      }
      if (!chat_id) {
        if (greeting !== "") {
          setMessages([
            {
              sender: "char",
              text: formatMessageText(greeting, isCharMessageDark),
              message_id: uuidv4(),
              message_timestamp: getUtcTimestamp(),
            },
          ]);
        } else {
          setMessages([]);
        }
      }
    })();
  };

  useEffect(() => {
    const pageEnterTime = Date.now();

    return () => {
      const pageLeaveTime = Date.now();
      const timeSpent = pageLeaveTime - pageEnterTime;
    };
  }, []);

  const fetchChat = async () => {
    (async () => {
      const userRequest = getChatMessages(chat_id);
      const response = await makeRequest<any, void>(userRequest);
      const formattedMessages = response.ui_context.map((message: Message) => ({
        ...message,
        text: formatMessageText(message.text, isCharMessageDark),
      }));
      setMessages(formattedMessages);
      setModelContext(response.current_llm_context);
      setActiveButtons(response.feedback_map);
      if (user) {
        if (response.chat_owner === user["https://chat.4wall.ai/username"]) {
          console.log("User is owner");
          setShowInput(true);
        } else if (response.chat_owner === "N/A") {
          // IF RETURNING AFTER LOGGING IN
          setUsername(user["https://chat.4wall.ai/username"]);
          setShowInput(true);
        }
      } else {
        throw new Error(`Server responded with ${response.status}`);
      }
      setCharacterLoading(false);
    })();
  };

  const endOfMessagesRef: RefObject<HTMLDivElement> =
    useRef<HTMLDivElement | null>(null);

  const scrollToBottom = (refVariable: RefObject<HTMLDivElement>) => {
    if (refVariable.current !== null) {
      refVariable.current.scrollIntoView({
        block: "end",
        inline: "center",
        behavior: "smooth",
      });
    }
  };

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    if (!isAuthenticated) {
      console.warn("Not authenticated");
      setUsername("N/A");
    } else {
      console.log("Authenticated");
      if (user) {
        const currentUsername = user["https://chat.4wall.ai/username"];
        setUsername(currentUsername);

        if (
          currentUsername &&
          character &&
          currentUsername === character.creator
        ) {
          console.log("User is creator");
          setUserIsCreator(true);
        }
      } else {
        setUsername("N/A");
      }
    }

    setUserLoading(false);
  }, [isAuthenticated, user, character]);

  useEffect(() => {
    fetchRemoteCharacterInfo();
    if (chat_id !== undefined) {
      fetchChat();
      setChatId(chat_id);
    } else {
      setShowInput(true);
    }
  }, [isAuthenticated, user, character_id, chat_id]);

  useEffect(() => {
    if (!deleteOperationPerfomed) {
      scrollToBottom(endOfMessagesRef);
    } else {
      setDeleteOperationPerformed(false);
    }
  }, [messages]);

  const navigate: NavigateFunction = useNavigate();
  const chatApiUrl = process.env.REACT_APP_CHAT_WEBSOCKET;
  if (!chatApiUrl) {
    throw new Error("API URL not defined in environment variables");
  }
  const websocketApiUrl = process.env.REACT_APP_4WALL_WEBSOCKET_API;
  if (!websocketApiUrl) {
    throw new Error("API URL not defined in environment variables");
  }
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    websocketUrl,
    {
      onOpen: () => console.log("WebSocket Opened"),
      onClose: () => console.log("WebSocket Closed"),
      onMessage: (event) => handleWebSocketMessage(event),
      shouldReconnect: (closeEvent) => true, // Automatically reconnect on close
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    }
  );

  const handleWebSocketMessage = (event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data);
      if (data.end) {
        if (data.error) {
          setAlertInfo({
            open: true,
            message:
              "Sorry, there was an error processing your message. Please try again.",
            severity: "error",
          });
          return;
        }
        setModelContext(data.context);
        setChatId(data.chat_id);
        setBlockInput(false);
        setMessages((prev) => {
          const lastMessageIndex = prev.length - 1;
          if (
            lastMessageIndex >= 0 &&
            prev[lastMessageIndex].sender === "char"
          ) {
            const lastMessage = { ...prev[lastMessageIndex] };
            lastMessage.text = lastMessage.text.trim(); // Trim the last message text

            return [...prev.slice(0, lastMessageIndex), lastMessage];
          }
          return prev;
        });

        return;
      } else if (data.edit) {
        return;
      }
      setMessages((prev) => {
        if (data.first) {
          setShowTyping(false);
          return [
            ...prev,
            {
              sender: "char",
              text: data.message,
              message_id: data.message_id,
              message_timestamp: data.message_timestamp,
            },
          ];
        } else {
          // If it's not the first message, append to the last 'char' message
          return prev.map((msg, index) =>
            index === prev.length - 1 && msg.sender === "char"
              ? {
                  ...msg,
                  text: msg.text + data.message,
                  message_id: data.message_id,
                  message_timestamp: data.message_timestamp,
                }
              : msg
          );
        }
      });
      // Format the complete message when the stream ends
      setMessages((prev) => {
        if (prev.length > 0 && prev[prev.length - 1].sender === "char") {
          const updatedPrev = [...prev];
          updatedPrev[prev.length - 1] = {
            ...prev[prev.length - 1],
            text: formatMessageText(
              prev[prev.length - 1].text,
              isCharMessageDark
            ),
          };
          return updatedPrev;
        }
        return prev;
      });
    } catch (e) {
      console.error("Error parsing JSON:", e);
    }
  };

  const handleSendMessage = () => {
    console.log("Sending message");
    setBlockInput(true);
    if (message === "") {
      console.log("huuuh");
      return;
    }
    const messageId = uuidv4();
    const chatIndex = messages.length;
    const timestamp = getUtcTimestamp();
    const newMessage = {
      sender: "user",
      text: message,
      message_id: messageId,
      message_timestamp: timestamp,
    };
    const updatedMessagesForApi = [...messages, newMessage];
    setMessages((prev) => [
      ...prev,
      {
        sender: "user",
        text: formatMessageText(message, isCharMessageDark),
        message_id: messageId,
        message_timestamp: timestamp,
      },
    ]);
    setShowTyping(true);
    setMessage("");

    const is_chat_start =
      messages.length === 0 ||
      (messages.length === 1 && messages[0].sender === "char");
    const messagePayload = {
      action: "sendmessage", // Specify the action to route in the backend
      input: message, // Include the message data
      is_chat_start: is_chat_start,
      session_id: sessionId,
      ui_context: updatedMessagesForApi.slice(0, 10), // This is only to access the greeeting message id and timestamp.
      character_id: character_id,
      chat_id: chatId,
      username: username,
      chat_index: chatIndex,
      input_uuid: messageId,
      input_timestamp: timestamp,
      regen: false,
    };
    setIsChatStart(false);
    sendJsonMessage(messagePayload);
  };

  const handleLikes = (
    isLike: boolean,
    index: number,
    message_id: string | undefined
  ) => {
    var turn = Math.floor(index / 2);
    setActiveButtons((prev) => ({
      ...prev,
      [index]: isLike ? "like" : "dislike",
    }));
    if (messages[0].sender === "char") {
      turn--;
    }
    var apiUrl = process.env.REACT_APP_FEEDBACK_API;
    if (!apiUrl) {
      throw new Error("API URL not defined in environment variables");
    }
    var feedbackData = {
      chat_id: chatId,
      feedback: isLike,
      message_id: message_id,
      message_timestamp: messages[index].message_timestamp,
      chat_index: index,
      feedback_mode: "thumbs",
    };
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feedbackData),
    })
      .then((response) => {
        if (!response.ok) {
          // alert("Oops somethinfg's wrong with like/dislike call"); TEMPORARY
          console.log("Oops something's wrong with like/dislike call");
        } else {
          console.log("Like/dislike successful!");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error liking/disliking:", error);
      });
  };

  const handleSave = () => {
    if (!isAuthenticated) {
      alert("Please log in to save your chat!");
      return;
    }
    if (user) {
      const username = user["https://chat.4wall.ai/username"];
      window.location.href = "/profile/" + username;
    } else {
      alert("wait for user load please...");
    }
  };

  const handleDelete = () => {
    console.log("Deleting chat with ID:", chatId);
    const deleteChat = async () => {
      const request_body = {
        item: "chat",
        chat_id: chatId,
      };
      const apiUrl = process.env.REACT_APP_DELETE_API;
      if (!apiUrl) {
        throw new Error("API URL not defined in environment variables");
      }

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(request_body),
        });

        if (response.ok) {
          const data = await response.json();
          // setMessages([]);
          setModelContext([]);
          setChatId("");
          setIsDeleted(true);
        } else {
          throw new Error(`Server responded with ${response.status}`);
        }
      } catch (error) {
        console.error("There was an error fetching the characters:", error);
      }
    };

    deleteChat();
  };

  useEffect(() => {
    if (isDeleted) {
      window.location.href = "/chat/" + character_id;
      setIsDeleted(false); // Reset the deletion state
    }
  }, [isDeleted, character_id, navigate]);

  const userMessageStyle = {
    backgroundColor: character.user_message_bg_color,
    color: character.user_text_color,
  };
  const charMessageStyle = {
    backgroundColor: `${character.char_message_bg_color}`,
    color: character.char_text_color,
  };

  useEffect(() => {
    if (charMessageStyle.color) {
      setIsCharMessageDark(isDarkColor(charMessageStyle.color));
    }
  }, [charMessageStyle.color]);

  const chatBackgroundImageStyle = {
    backgroundImage: `
      linear-gradient(to top, rgba(0,0,0,0.6) 10%, transparent 40%),
      linear-gradient(to bottom, rgba(0,0,0,0.7) 10%, transparent 40%),
      url(${character.chat_background_url})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed", // This can ensure the background is fixed during scroll
  };
  const chatBackgroundColorStyle = {
    background: `linear-gradient(to top, rgba(0,0,0,0.3) 10%, transparent 40%), 
    linear-gradient(to bottom, rgba(0,0,0,0.3) 10%, transparent 40%), 
    ${
      darkMode && character.chat_background_color === "#f2f2f2"
        ? "#333"
        : character.chat_background_color
    }`,
  };

  const handleRegenerate = () => {
    const isLastMessageFromBot =
      messages.length > 0 && messages[messages.length - 1].sender !== "user";
    const char_message_id = messages[messages.length - 1].message_id || "";
    const char_message_timestamp =
      messages[messages.length - 1].message_timestamp;
    const updatedMessages = isLastMessageFromBot
      ? messages.slice(0, -1)
      : [...messages];

    // Update the messages state to remove the bot's last message
    setMessages(updatedMessages);
    setShowTyping(true);
    (async () => {
      const userRequest = updateMessage(char_message_id, chatId, "regen");
      const requestBody = {
        message_timestamp: char_message_timestamp,
      };
      const response = await makeRequest<any, any>(userRequest, requestBody);
      console.log(response);
    })();
  };

  const handleEditMessage = (messageIndex: number, sender: string) => {
    setEditingMessageIndex(messageIndex);
    setSenderBeingEdited(sender);
    const originalFormatText = reverseFormatMessageText(
      messages[messageIndex].text
    );
    setEditingMessageText(originalFormatText);
  };

  const handleSaveEditedMessage = (index: number) => {
    if (editingMessageIndex !== null && editingMessageText.trim() !== "") {
      const formattedText = formatMessageText(
        editingMessageText,
        isCharMessageDark
      );
      const updatedMessages = messages.map((msg, idx) =>
        idx === editingMessageIndex ? { ...msg, text: formattedText } : msg
      );
      setMessages(updatedMessages);
      setActiveButtons((prev) => ({
        ...prev,
        [index]: "",
      }));
      (async () => {
        const userRequest = updateMessage(
          messages[index].message_id || "",
          chatId,
          "edit"
        );
        const requestBody = {
          message_timestamp: messages[index].message_timestamp,
          edited_message: formattedText,
        };
        const response = await makeRequest<any, any>(userRequest, requestBody);
      })();
      setEditingMessageIndex(-1);
      setEditingMessageText("");
    }
  };

  const handleDeleteMessage = (
    index: number,
    message_id: string,
    message_timestamp: string | undefined
  ) => {
    console.log("Deleting message with ID:", message_id);
    (async () => {
      const userRequest = deleteMessage(message_id, chat_id);
      const requestBody = {
        message_timestamp: message_timestamp,
        index: index,
      };
      const response = await makeRequest<any, any>(userRequest, requestBody);
      console.log(response);
      if (response.success) {
        showAlert("Message deleted", "success");
        setMessages((prev) => prev.filter((_, i) => i !== index));
        setActiveButtons((prev) => {
          const updatedButtons = { ...prev };
          delete updatedButtons[index];
          return updatedButtons;
        });
        setDeleteOperationPerformed(true);
      } else {
        showAlert("Error deleting message", "error");
      }
      setModelContext(response.context);
    })();
  };

  if (isLoading || characterLoading) {
    return (
      <div className="loading-centered">
        {" "}
        <CircularProgress color="inherit" />
      </div>
    );
  }
  const refetchUserAssets = async (username: string) => {
    (async () => {
      const userRequest = getUserAssets(username);
      const response = await makeRequest<UserAssets, void>(userRequest);
      setUserAssets(response);
    })();
  };

  const handleBackClick = async () => {
    setChatPaused(true);
    try {
      refetchUserAssets(username);
      navigate("/"); // Navigate to home page
    } catch (error) {
      console.error("Error in refetching user assets: ", error);
    }
  };

  const handleUsernameClick = () => {
    navigate(`/profile/${encodeURIComponent(character.creator)}`);
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (!e.shiftKey) {
        e.preventDefault();
        if (!isMobile) {
          handleSendMessage();
        }
      }
    }
  };

  return (
    <div className="background">
      {/* <Header /> */}
      <div
        className="chat-container"
        style={
          character.chat_background_url !== ""
            ? chatBackgroundImageStyle
            : character.chat_background_color === "#f2f2f2" ||
                character.chat_background_color === "#333"
              ? undefined
              : chatBackgroundColorStyle
        }
      >
        <div className="chat-header">
          <div style={{ display: "flex" }}>
            <ArrowBackIosNewRoundedIcon
              className="back-arrow"
              onClick={handleBackClick}
              sx={{ margin: "auto", cursor: "pointer" }}
            />
            <img
              className="chat-header__chardp"
              onError={(e) => {
                const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                target.onerror = null; // prevents looping
                target.src =
                  "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
              }}
              src={character.image_url}
              alt={character.name}
            />
            <div style={styles.username} onClick={handleUsernameClick}>
              <h2 className="character-name">{character.name}</h2>
              <span className="character-creator-username">
                @{character.creator}
              </span>
            </div>
          </div>

          <div className="dropdown" ref={dropdownRef}>
            <SharePopup
              url={`https://${process.env.REACT_APP_ENVIRONMENT}.4wall.ai/chat/${character_id}`}
              name={character.name}
              id={character.character_id}
            />

            <button
              onClick={() => {
                setDropdownOpen(!dropdownOpen);
              }}
            >
              ⋮
            </button>
            {dropdownOpen && (
              <div className="dropdown-content">
                <p onClick={handleSave}>Save Chat</p>
                <p onClick={handleDelete}>Delete & Start New Chat</p>
                {userIsCreator && (
                  <p onClick={() => navigate(`/create/${character_id}`)}>
                    Edit Character
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="chat-window">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`chat-message ${message.sender}`}
              style={
                message.sender === "user" ? userMessageStyle : charMessageStyle
              }
            >
              <div className="message-content">
                {editingMessageIndex === index ? (
                  <>
                    <p style={{ fontStyle: "italic", padding: "0" }}>Editing</p>
                    <textarea
                      className="edit-message-input"
                      value={editingMessageText}
                      onChange={(e) => setEditingMessageText(e.target.value)}
                      rows={
                        editingMessageText.length / 25
                      } /* Start with a single line */
                      cols={100}
                      maxLength={700}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          /* Allow Shift+Enter for new lines */
                          e.preventDefault(); /* Prevent default to avoid new line in textarea */
                          handleSaveEditedMessage(index);
                        }
                      }}
                      onBlur={() => handleSaveEditedMessage(index)}
                    />
                  </>
                ) : (
                  // <>{message.text}</>
                  <div dangerouslySetInnerHTML={{ __html: message.text }} />
                )}
                {message.sender === "user" && (
                  <div className="feedback-buttons">
                    {index === messages.length - 2 && (
                      <>
                        {editingMessageIndex === -1 ? (
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditMessage(index, "user")}
                          ></EditIcon>
                        ) : (
                          <DoneIcon
                            onClick={() => handleSaveEditedMessage(index)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </>
                    )}

                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleDeleteMessage(
                          index,
                          message.message_id || "",
                          message.message_timestamp
                        );
                      }}
                    />
                  </div>
                )}
                {message.sender === "char" && (
                  <div className="feedback-buttons">
                    {index === messages.length - 1 && messages.length > 1 && (
                      <>
                        {editingMessageIndex === -1 ? (
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditMessage(index, "char")}
                          ></EditIcon>
                        ) : (
                          <DoneIcon
                            onClick={() => handleSaveEditedMessage(index)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        <RefreshIcon
                          style={{ cursor: "pointer" }}
                          onClick={handleRegenerate}
                        ></RefreshIcon>
                      </>
                    )}
                    <ThumbUpIcon
                      className={`like-button ${
                        activeButtons[index] === "like" ? "active" : ""
                      }`}
                      onClick={() =>
                        handleLikes(true, index, message.message_id)
                      }
                    ></ThumbUpIcon>
                    <ThumbDownIcon
                      className={`dislike-button ${
                        activeButtons[index] === "dislike" ? "active" : ""
                      }`}
                      onClick={() =>
                        handleLikes(false, index, message.message_id)
                      }
                    ></ThumbDownIcon>

                    {index > 0 && (
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleDeleteMessage(
                            index,
                            message.message_id || "",
                            message.message_timestamp
                          );
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
          {showTyping && (
            <div className="chat-message char" style={charMessageStyle}>
              <div>
                <img
                  className="typing"
                  src="/images/typing.gif"
                  alt="Typing..."
                />
              </div>
            </div>
          )}
          <div
            className="shadow-scroll-container"
            style={{ height: 1 + "px" }}
            ref={endOfMessagesRef}
          ></div>
        </div>
        <div className="chat-input">
          {showInput && (
            <>
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                maxLength={700}
                placeholder="Type your message..."
                style={{ width: "100%", padding: "10px" }} // Example styling
              />

              <button
                disabled={blockInput}
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  border: "none",
                }}
                onClick={handleSendMessage}
              >
                {!blockInput && <SendIcon fontSize="large" />}
                {blockInput && <CircularProgress color="inherit" />}
              </button>
            </>
          )}
        </div>
        <Snackbar
          open={alertInfo.open}
          autoHideDuration={6000}
          onClose={closeAlert}
        >
          <Alert
            onClose={closeAlert}
            severity={alertInfo.severity}
            sx={{ width: "100%" }}
          >
            {alertInfo.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

const styles = {
  username: {
    margin: "auto",
    cursor: "pointer",
    transition: "transform 0.3s",
    "&:hover": { transform: "scale(1.1)" },
  },
};

export default ChatMode;
