import React from "react";
import ReactPlayer from "react-player";

function Videobox() {
  return (
    <div className="video-box">
      <ReactPlayer
        controls={true}
        className="react-player"
        url="https://4thwall-assets.s3.amazonaws.com/4WallAI_Teaser.mp4"
        width="100%"
        height="100%"
        config={{
          youtube: {
            playerVars: { modestbranding: 1 },
          },
        }}
        playbackRate={1}
        muted={true}
      />
    </div>
  );
}

export default Videobox;
