import React, { useState, useContext } from "react";
import { Character } from "../../types";
import "./Character.css";
import ChatIcon from "@mui/icons-material/Chat";
import CharacterImage from "../Misc/CharacterImage";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { track } from "@amplitude/analytics-node";

export interface CharacterProps {
  character: Character;
  onClick?: () => void;
  theme?: boolean;
}

function CharacterTrending({ character, onClick, theme }: CharacterProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const handleOtherUserClick = (event: any) => {
    // track("click_other_user", { username: character.creator });
    event.stopPropagation();
    navigate(`/profile/${encodeURIComponent(character.creator)}`);
  };
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  function formatInteractionsCount(count: number) {
    if (count < 1000) return count; // Return the original count if less than 1000
    return (count / 1000).toFixed(count % 1000 === 0 ? 0 : 1) + "k"; // Otherwise, format as '1.2k', '3k', etc.
  }
  return (
    <div
      className={`trending-character-card fadeInUp`} // Add the animation class when the image is loaded
      key={character.character_id}
      onClick={onClick}
    >
      {!isLoaded && <Skeleton variant="rectangular" width={210} height={118} />}
      <img
        src={character.image_url}
        alt={character.name}
        onLoad={() => setIsLoaded(true)}
        onError={(e) => {
          const target = e.target as HTMLImageElement;
          target.onerror = null;
          target.src =
            "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{character.name}</h3>
        {character.bio != "undefined" ? (
          <p style={{ padding: "0rem 0rem" }}>{character.bio}</p>
        ) : (
          <p></p>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <p
            className="trending-char-info"
            onClick={(e) => handleOtherUserClick(e)}
          >
            @{character.creator}
          </p>
          <div className="trending-char-interactions">
            <ChatIcon style={{ fontSize: "2em" }} />{" "}
            {formatInteractionsCount(character.interactions)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CharacterTrending;
