import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./components/Homepage";
import ChatMode from "./components/Chat/ChatMode";
import { PlatformContext } from "./contexts/PlatformContext";
import CreateBotcast from "./components/Botcasts/CreateBotcast";
import Botcast from "./components/Botcasts/Botcast";
import Create from "./components/Create/Create";
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import { CircularProgress } from "@mui/material";
import FAQ from "./components/FAQ";
import AuthHandler from "./components/Auth/AuthHandler";
import UsernameCollect from "./components/Misc/UsernameCollect";
import useRequest from "./apis/useRequest";
import BottomNavbar from "./BottomNavbar";
import { useLocation } from "react-router-dom";
import { Character, UserAssets } from "./types";
import { getUserAssets } from "./apis/request";
import Sidebar from "./components/Landing/Sidebar";
import { getFeaturedCharacters } from "./apis/request";
import { FeaturedLists } from "./types";
import NotificationSidebar from "./components/Landing/NotificationSidebar";
import * as amplitude from "@amplitude/analytics-browser";

// import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
// Create and Install Session Replay Plugin
// const sessionReplayTracking = sessionReplayPlugin();
// amplitude.add(sessionReplayTracking);
// Your existing initialization logic with Browser SDK
amplitude.init("9d5fcb1a6a7083251a986a134e131720");
function App() {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [username, setUsername] = useState<string>("");
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const [socialUrls, _] = useState<{ [key: string]: string }>({
    Discord: "https://discord.gg/EUebsQR5KE",
    Reddit: "https://www.reddit.com/r/4WallAI/",
    X: "https://x.com/4WallAI_",
  });
  const [darkMode, setDarkMode] = useState<boolean>(true);
  const [featuredLists, setFeaturedLists] = useState<FeaturedLists>({
    anime: [],
    gaming: [],
    moviesTV: [],
    isekaiRPG: [],
  });
  const [userAssetsLoading, setUserAssetsLoading] = useState<boolean>(true); // TODO: Remove this once we have a loading screen
  const [showChats, setShowChats] = useState<boolean>(false);
  const [NSFW, setNSFW] = useState<boolean>(() => {
    const storedNSFW = localStorage.getItem("NSFW");
    return storedNSFW !== null ? storedNSFW === "true" : false;
  });

  const [userAssets, setUserAssets] = useState<UserAssets>({
    characters: [],
    botcasts: [],
    chats: [],
    personas: [],
  });
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const makeRequest = useRequest();

  function NavigationDependentComponent() {
    const location = useLocation();

    return (
      <div>
        {isMobile &&
          (location.pathname === "/" ||
            location.pathname.includes("profile/")) && <BottomNavbar />}
      </div>
    );
  }
  const fetchUserAssets = async (username: string) => {
    const response = await makeRequest<UserAssets, void>(
      getUserAssets(username)
    );
    console.log(response);
    setUserAssets(response);
    setUserAssetsLoading(false);
  };
  const fetchFeaturedCharacters = async () => {
    const response = await makeRequest<FeaturedLists, void>(
      getFeaturedCharacters()
    );
    setFeaturedLists(response);
  };

  useEffect(() => {
    localStorage.setItem("NSFW", NSFW.toString());
  }, [NSFW]);

  useEffect(() => {
    fetchFeaturedCharacters();
    // function handleResize() {
    //   setIsMobile(window.innerWidth < 768);
    // }
    // window.addEventListener("resize", handleResize);
    // return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      amplitude.setUserId(user.sub);
      amplitude.track("Logged in user on platform", {
        username: user["https://chat.4wall.ai/username"],
      });
      console.log("User: ", user);
      if (user["https://chat.4wall.ai/new_social_user"]) {
        amplitude.track("signup", {
          username: user["https://chat.4wall.ai/username"],
        });
        setUserAssetsLoading(false);
      } else {
        setUserAssetsLoading(true);
        if (user["https://chat.4wall.ai/username"] != undefined) {
          setUsername(user["https://chat.4wall.ai/username"]);
          fetchUserAssets(user["https://chat.4wall.ai/username"]);
        } else {
          setUserAssetsLoading(false);
        }
      }
    } else {
      setUserAssetsLoading(false);
    }
  }, [isAuthenticated, user]); // Depend on isAuthenticated and user

  if (isLoading || userAssetsLoading) {
    return (
      <div
        className="loading-centered"
        style={darkMode ? { backgroundColor: "#333" } : {}}
      >
        {" "}
        <CircularProgress
          style={
            darkMode
              ? { color: "white" }
              : { color: "var(--primary-text-color)" }
          }
        />
      </div>
    );
  }
  return (
    <PlatformContext.Provider
      value={{
        username,
        characters,
        setCharacters,
        userAssets,
        setUserAssets,
        showChats,
        setShowChats,
        darkMode,
        setDarkMode,
        NSFW,
        setNSFW,
        featuredLists,
        isMobile,
        socialUrls,
      }}
    >
      <Router>
        <div className="app-container">
          <Sidebar />
          {/* <NotificationSidebar /> */}
          <div className="content-container">
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route
                path="/chat/:character_id/:chat_id?"
                element={<ChatMode />}
              />
              <Route path="/create/:character_id?" element={<Create />} />
              <Route
                path="/profile/:username/:user_id?"
                element={<ProfilePage />}
              />
              <Route path="/botcast/create" element={<CreateBotcast />} />
              <Route
                path="/botcast/view/:character_id1/:character_id2/:botcast_id?"
                element={<Botcast />}
              />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/username" element={<UsernameCollect />} />
            </Routes>
          </div>
          <AuthHandler />
          <NavigationDependentComponent />
        </div>
      </Router>
    </PlatformContext.Provider>
  );
}

export default App;
