import React, { useState, useContext } from "react";
import { Button } from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { PlatformContext } from "./contexts/PlatformContext";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import SearchOverlay from "./components/Misc/SearchOverlay";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Login from "./components/Auth/Login";
import PersonIcon from "@mui/icons-material/Person";
import SignupWall from "./components/Misc/SignupWall";

function BottomNavbar({ characters }: any) {
  const [showSearch, setShowSearch] = useState(false);
  const [showSignupWall, setShowSignupWall] = useState(false);

  const context = useContext(PlatformContext);
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const handleClickRequiringSignUp = (route: string) => {
    if (isAuthenticated) {
      navigate(route);
    } else {
      setShowSignupWall(true);
    }
  };
  const keyDownHandler = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "k") {
      console.log("You just pressed Ctrl+K or Cmd+K!");
      setShowSearch(!showSearch);
      event.preventDefault();
    }
  };

  const handleProfileClick = () => {
    if (user && user["https://chat.4wall.ai/user_id"]) {
      handleClickRequiringSignUp(
        `/profile/${user["https://chat.4wall.ai/username"]}/${user["https://chat.4wall.ai/user_id"]}`
      );
    } else {
      alert("Please sign in");
    }
  };
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const { showChats, setShowChats, darkMode, setDarkMode } = context;
  return (
    <div>
      {showSearch && (
        <SearchOverlay
          isOpen={showSearch}
          onClose={() => setShowSearch(false)}
        />
      )}
      {showSignupWall && <SignupWall setShowSignupWall={setShowSignupWall} />}

      <div className="bottom-navbar">
        {" "}
        <p></p>
        <ChatBubbleIcon
          style={{
            margin: "auto 0.8rem",
            color: "var(--primary-text-color)",
            cursor: "pointer",
            fontSize: "2rem",
            transition: "transform 0.1s", // Add transition for smooth scaling
          }}
          onClick={() => setShowChats(!showChats)}
          onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")} // Scale up on hover
          onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")} // Scale back to normal
        />
        <SearchIcon
          style={{
            margin: "auto 0.8rem",
            color: "var(--primary-text-color)",
            cursor: "pointer",
            fontSize: "2.2rem",
            transition: "transform 0.1s", // Add transition for smooth scaling
          }}
          onClick={() => setShowSearch(true)}
          onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")} // Scale up on hover
          onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")} // Scale back to normal
        />
        <Button
          onClick={() =>
            navigate(`/botcast/create`, { state: { characters: characters } })
          }
          variant="contained"
          className="botcast-btn"
        >
          BOTCASTS
        </Button>
        {!isAuthenticated && <Login redirectUrl="/" />}
        <AddIcon
          onClick={() => handleClickRequiringSignUp("/create")}
          style={{
            margin: "auto 0.8rem",
            color: "var(--primary-text-color)",
            cursor: "pointer",
            fontSize: "2.6rem",
            transition: "transform 0.1s", // Add transition for smooth scaling
          }}
          onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")} // Scale up on hover
          onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")} // Scale back to normal
        />
        {isAuthenticated && (
          <PersonIcon
            // fontSize={!isMobile ? "large" : "medium"}
            fontSize="large"
            className="profile-icon"
            onClick={handleProfileClick}
          />
        )}
      </div>
    </div>
  );
}

export default BottomNavbar;
