import React, { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./Create.css";
interface Props {
  infoText: string;
  title: string;
}

const InfoBubble: React.FC<Props> = ({ infoText, title }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <HelpIcon onClick={handleClickOpen} style={{ cursor: "pointer" }} />
      <Dialog onClose={handleClose} open={open} className="info-popup">
        <DialogTitle style={{ color: "var(--primary-text-color" }}>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ color: "var(--primary-text-color" }} dividers>
          <div dangerouslySetInnerHTML={{ __html: infoText }} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InfoBubble;
