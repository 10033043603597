import React, { useRef } from "react";
import { CharacterState } from "./Create";
import InfoBubble from "./InfoBubble";
import { Flex } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

interface AttributesProps {
  character: CharacterState;
  setCharacter: React.Dispatch<React.SetStateAction<CharacterState>>;
}

function Attributes({ character, setCharacter }: AttributesProps) {
  const sliderRef = useRef(null);
  const attributesList: (keyof CharacterState["attributes"])[] = [
    "adventurous",
    "empathy",
    "funny",
    "intelligent",
  ];

  const handleSliderChange = (
    event: Event,
    newValue: number | number[],
    attribute: string
  ) => {
    setCharacter({
      ...character,
      attributes: {
        ...character.attributes,
        [attribute]: newValue,
      },
    });
  };

  return (
    <div className="attributes-container">
      <h2 style={{ display: "flex", justifyContent: "center" }}>
        Modify Personality
        <InfoBubble
          title="Attributes"
          infoText="<p>Adjust the sliders to shape your character's personality traits. Each slider influences a different aspect of how your character will interact within the chat:</p>
<ul>
  <li><strong>Adventurous:</strong> Controls their willingness to engage in and suggest daring activities or ideas.</li>
  <li><strong>Empathetic:</strong> Determines how well your character can understand and respond to emotions.</li>
  <li><strong>Funny:</strong> Dictates the frequency and quality of humor in your character's responses.</li>
  <li><strong>Intelligent:</strong> Affects the complexity, depth, and accuracy of your character's dialogue.</li>
</ul>
<p>Move the sliders to reflect the personality you envision for your character. Finding the perfect mix will make your character's interactions more authentic and engaging.</p>
<p>Please note that while these settings guide our model, they serve as general directions rather than strict rules. There may be instances where a slight adjustment to a slider does not result in a significant change in your character’s behavior, as personality expression can be nuanced.</p>"
        />
      </h2>
      {attributesList.map((attribute) => (
        <div key={attribute}>
          <label>
            {attribute == "empathy" ? (
              <p>Empathetic:</p>
            ) : (
              <p>{attribute.charAt(0).toUpperCase() + attribute.slice(1)}:</p>
            )}
          </label>
          <Slider
            aria-label={attribute}
            defaultValue={50}
            valueLabelDisplay="auto"
            onChange={(event, newValue) =>
              handleSliderChange(event, newValue, attribute)
            }
            value={character.attributes[attribute] ?? 50}
            step={10}
            marks
            min={0}
            max={100}
          />
        </div>
      ))}
    </div>
  );
}

export default Attributes;
