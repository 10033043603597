import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Character, Chat } from "../../types";
import { PlatformContext } from "../../contexts/PlatformContext";
import ChatCard from "./ChatCard";

export interface SavedChatsProps {
  character_id: string;
  chat_id: string;
  name: string;
  last_interaction: string;
  image_url: string;
}

function SavedChats() {
  const context = useContext(PlatformContext);
  const [chats, setChats] = useState<SavedChatsProps[]>([]);
  const { user, isAuthenticated, isLoading } = useAuth0();
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { characters, setCharacters, userAssets, setUserAssets } = context;

  const fetchChats = async () => {
    if (isAuthenticated && user) {
      const sortedChats = userAssets.chats.sort(
        (a, b) =>
          new Date(b.last_interaction).getTime() -
          new Date(a.last_interaction).getTime()
      );
      setChats(sortedChats);
    }
  };

  useEffect(() => {
    fetchChats();
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="character-list">
      {chats.map((char) => (
        <ChatCard
          key={char.chat_id}
          {...char}
          setChats={setChats}
          setUserAssets={setUserAssets}
        />
      ))}
    </div>
  );
}

export default SavedChats;
