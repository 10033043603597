import React, { useState, useContext, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Avatar,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import ReactPlayer from "react-player";
import { SocialIcon } from "react-social-icons";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { PlatformContext } from "../../contexts/PlatformContext";
import SearchOverlay from "../Misc/SearchOverlay";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import Login from "../Auth/Login";
import SignupWall from "../Misc/SignupWall";
import { Chat } from "../../types";
import CSSTransition from "react-transition-group/CSSTransition";
import MultiChatPopup from "../Misc/MultiChatPopup";
export interface CharacterGroup {
  character: Chat;
  chats: Chat[];
}

const Sidebar: React.FC = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [showSignupWall, setShowSignupWall] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showMultiChatPopup, setShowMultiChatPopup] = useState<boolean>(false);
  const [selectedCharacterMultiChats, setSelectedCharacterMultiChats] =
    useState<Chat[]>([]);
  const [continueCharacters, setContinueCharacters] = useState<
    CharacterGroup[]
  >([]);
  const navigate = useNavigate();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const {
    setShowChats,
    characters,
    userAssets,
    showChats,
    isMobile,
    socialUrls,
  } = context;
  const [isOpen, setIsOpen] = useState<boolean>(isMobile ? showChats : true);

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    setIsOpen(showChats);
  }, [showChats]);

  useEffect(() => {
    // if (!isMobile) {
    //   return;
    // }
    if (window.location.pathname == "/" && !isMobile) {
      setIsOpen(true);
      setShowChats(true);
    }
    if (window.location.pathname.includes("chat/")) {
      setIsOpen(false);
      setShowChats(false);
    }
  }, [window.location.pathname]);

  const aggregateChats = (chats: Chat[]) => {
    const groups: { [characterId: string]: CharacterGroup } = {};

    chats.forEach((chat) => {
      if (!groups[chat.character_id]) {
        groups[chat.character_id] = { character: chat, chats: [] };
      } else {
        groups[chat.character_id].character = chat;
      }
      groups[chat.character_id].chats.push(chat);
    });

    Object.values(groups).forEach((group) =>
      group.chats.sort(
        (a, b) =>
          new Date(b.last_interaction).getTime() -
          new Date(a.last_interaction).getTime()
      )
    );

    const sortedChatGroups = Object.values(groups).sort((a, b) => {
      // Convert last_interaction to a Date object and compare
      const dateA = new Date(a.character.last_interaction);
      const dateB = new Date(b.character.last_interaction);
      return dateB.getTime() - dateA.getTime(); // Sort in descending order
    });
    setContinueCharacters(Object.values(sortedChatGroups));
  };

  const [innerVisible, setInnerVisible] = useState(false);

  useEffect(() => {
    // When the sidebar opens, wait a bit before showing inner components
    if (isOpen) {
      const timer = setTimeout(() => {
        setInnerVisible(true);
      }, 200); // adjust timing based on your needs
      return () => clearTimeout(timer);
    } else {
      setInnerVisible(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isAuthenticated) {
      aggregateChats(userAssets.chats);
    }
  }, []);

  const handleContinueCharacterSelect = (group: CharacterGroup): void => {
    if (group.chats.length === 1) {
      // If there's only one chat, navigate directly to that chat
      navigate(
        `/chat/${group.chats[0].character_id}/${group.chats[0].chat_id}`
      );
    } else {
      // If there are multiple chats, open a pop-up
      setShowMultiChatPopup(true);
      setSelectedCharacterMultiChats(group.chats);
    }
  };
  const toggleSidebar = () => {
    console.log("Toggling sidebar to ", !isOpen);
    setIsOpen(!isOpen);
    if (isMobile) {
      setShowChats(!isOpen);
    }
  };

  const handleClickRequiringSignUp = (route: string) => {
    if (isAuthenticated) {
      navigate(route);
    } else {
      setShowSignupWall(true);
    }
  };

  const handleProfileClick = () => {
    if (user && user["https://chat.4wall.ai/user_id"]) {
      handleClickRequiringSignUp(
        `/profile/${user["https://chat.4wall.ai/username"]}/${user["https://chat.4wall.ai/user_id"]}`
      );
    } else {
      alert("Please sign in");
    }
  };
  console.log("Social URLS: ", socialUrls);

  return (
    <Box
      sx={{
        width: isMobile
          ? isOpen || showChats
            ? "30rem"
            : "0rem"
          : isOpen
            ? "20rem"
            : window.location.pathname.includes("chat")
              ? "0rem"
              : "1rem",
        height: "100vh",
        color: "white",
        display: "flex",
        flex: "1 1 auto",
        boxSizing: "border-box",
        flexDirection: "column",
        borderRight: isOpen ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
        bgcolor: isOpen ? "rgba(0,0,0,0.5)" : "transparent", // Transparent with opacity
        transition: "width 0.2s ease", // Smooth transition for width change
      }}
    >
      {showSignupWall && <SignupWall setShowSignupWall={setShowSignupWall} />}
      <Box sx={{ display: "flex" }}>
        {isMobile ? (
          !window.location.pathname.includes("chat/") && (
            <IconButton
              onClick={toggleSidebar}
              sx={{ alignSelf: "flex-end", m: 1, color: "white" }}
            >
              <MenuIcon />
            </IconButton>
          )
        ) : (
          <IconButton
            onClick={toggleSidebar}
            sx={{ alignSelf: "flex-end", m: 1, color: "white" }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {innerVisible && (
          <h1 onClick={() => navigate("/")} style={styles.logo}>
            4Wall AI{" "}
          </h1>
        )}
      </Box>

      {showSearch && (
        <SearchOverlay
          isOpen={showSearch}
          onClose={() => setShowSearch(false)}
        />
      )}
      {innerVisible && (
        <Box>
          <Divider sx={styles.divider} />
          {!isMobile && (
            <List>
              <ListItem button onClick={() => setShowSearch(true)}>
                <ListItemIcon sx={styles.icon}>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Search"
                  primaryTypographyProps={styles.sidebarText}
                />
              </ListItem>
              <ListItem
                button
                onClick={() => handleClickRequiringSignUp("/create")}
              >
                <ListItemIcon sx={styles.icon}>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Create a character"
                  primaryTypographyProps={styles.sidebarText}
                />
              </ListItem>
              <ListItem button>
                <Button
                  onClick={() => {
                    if (!isAuthenticated) {
                      loginWithRedirect({
                        appState: { returnTo: "/botcast/create" },
                      });
                      return;
                    }
                    navigate(`/botcast/create`, {
                      state: { characters: [] },
                    });
                  }}
                  variant="contained"
                  className="botcast-btn"
                >
                  BOTCASTS
                </Button>
              </ListItem>
            </List>
          )}

          <Box>
            <div className="video-box-side">
              <ReactPlayer
                controls={true}
                className="react-player"
                url="https://4thwall-assets.s3.amazonaws.com/4WallAI_Teaser.mp4"
                width="100%"
                height="100%"
                config={{
                  youtube: {
                    playerVars: { modestbranding: 1 },
                  },
                }}
                playbackRate={1}
                muted={true}
                light={"/threed.png"}
              />
            </div>
          </Box>
          {/* <Divider sx={styles.divider} /> */}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <SocialIcon
              url={socialUrls.Reddit}
              target="_blank"
              style={styles.socialIconStyle}
              fgColor="var(--orange-brand-accent)"
              bgColor="transparent"
            />
            <SocialIcon
              url={socialUrls.Discord}
              target="_blank"
              network="discord"
              style={styles.socialIconStyle}
              fgColor="var(--orange-brand-accent)"
              bgColor="transparent"
            />
            <SocialIcon
              url={socialUrls.X}
              className="social-icon"
              target="_blank"
              fgColor="var(--orange-brand-accent)"
              bgColor="transparent"
              style={styles.socialIconStyle}
            />
          </Box>
          {isAuthenticated && (
            <>
              <Divider sx={styles.divider} />
              <Typography
                variant="h1"
                sx={{ ...styles.sidebarText, pl: 2, mt: 1 }}
              >
                Chats
              </Typography>
            </>
          )}

          {/* <Box sx={styles.chats}> */}
          <List sx={styles.chats}>
            {continueCharacters.map((group) => (
              <ListItem
                button
                key={group.character.character_id}
                onClick={() => handleContinueCharacterSelect(group)}
              >
                <ListItemIcon>
                  <Avatar
                    alt={group.character.name}
                    src={group.character.image_url}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={group.character.name}
                  primaryTypographyProps={styles.sidebarText}
                />
              </ListItem>
            ))}
          </List>
          {/* </Box> */}

          {!isMobile && (
            // <Box>
            <>
              <Divider sx={styles.divider} />
              <List sx={{ mt: "auto" }}>
                {!isAuthenticated ? (
                  <ListItem button>
                    <Login redirectUrl="/" />{" "}
                  </ListItem>
                ) : (
                  <ListItem button onClick={handleProfileClick}>
                    <ListItemIcon sx={styles.icon}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      sx={styles.text}
                      primary="Profile"
                      primaryTypographyProps={styles.sidebarText}
                    />
                  </ListItem>
                )}
              </List>
            </>
            // </Box>
          )}
        </Box>
      )}
      <CSSTransition
        in={showMultiChatPopup !== null}
        timeout={300}
        classNames="popup"
        unmountOnExit
      >
        {showMultiChatPopup ? (
          <MultiChatPopup
            open={showMultiChatPopup}
            chats={selectedCharacterMultiChats}
            onClose={() => setShowMultiChatPopup(false)}
          />
        ) : (
          <></>
        )}
      </CSSTransition>
    </Box>
  );
};

const styles = {
  logo: {
    cursor: "pointer",
    fontFamily: "var(--font_b_extended)",
    color: "var(--primary-text-color)",
    marginTop: "0.67em",
    fontSize: "1.4rem",
    textShadow: "2px 2px 4px #000000",
    "&:hover": {
      color: "var(--orange-brand-accent)",
    },
  },
  icon: {
    color: "var(--orange-brand-accent)",
  },
  chats: {
    fontFamily: "var(--font_b)",
    overflowY: "auto",
    maxHeight: window.innerHeight < 768 ? "65vh" : "34vh",
    "&::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:active, &::-webkit-scrollbar-thumb:hover":
      {
        backgroundColor: "var(--orange-brand-accent)", // replace with your desired color
      },
    "&::-webkit-scrollbar": {
      width: "2px",
      height: "2px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "var(--orange-brand-accent)",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "var(--orange-brand-accent)",
    },
    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: "var(--orange-brand-accent)",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
    "&::-webkit-resizer": {
      backgroundColor: "transparent",
    },
  },
  divider: {
    width: "90%",
    margin: "auto",
    borderColor: "rgba(255, 255, 255, 0.3)",
  },
  text: {
    font: "var(--font_b_extended)",
  },
  sidebarText: {
    fontFamily: "var(--font_b)",
    fontSize: "0.9rem",
  },
  socialIconStyle: {
    height: 50,
    width: 50,
    margin: "0 1.4rem",
    bgColor: "green",
    fgColor: "blue",
  },
};
export default Sidebar;
