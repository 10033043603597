import React from "react";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Chat } from "../../types"; // Import your Chat type
import CharacterImage from "./CharacterImage";
import "./Misc.css";
interface MultiChatPopupProps {
  open: boolean;
  chats: Chat[];
  onClose: () => void;
}

function MultiChatPopup({ open, chats, onClose }: MultiChatPopupProps) {
  // Sort chats in descending order by last interaction
  const sortedChats = chats.sort(
    (a, b) =>
      new Date(b.last_interaction).getTime() -
      new Date(a.last_interaction).getTime()
  );
  const navigate = useNavigate();

  const handleChatSelect = (chat: Chat) => {
    navigate(`/chat/${chat.character_id}/${chat.chat_id}`);
    onClose();
  };
  return (
    <Dialog onClose={onClose} open={open}>
      {/* <CharacterImage
        image_url={sortedChats[0].image_url}
        name={sortedChats[0].name}
      /> */}
      <img
        src={sortedChats[0].image_url}
        alt={sortedChats[0].name}
        className="multi-chat-popup-img"
        onError={(e) => {
          const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
          target.onerror = null; // prevents looping
          target.src =
            "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
        }}
      />
      <h2 style={{ fontFamily: "Transducer Extended", margin: "auto" }}>
        {sortedChats[0].name}
      </h2>
      <DialogTitle>Select chat to continue</DialogTitle>
      <List>
        {sortedChats.map((chat) => (
          <ListItem
            button
            onClick={() => handleChatSelect(chat)}
            key={chat.chat_id}
          >
            <ListItemText
              primary={`Chat date: ${new Date(
                chat.last_interaction
              ).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}`}
            />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default MultiChatPopup;
