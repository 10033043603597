import React, { useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PersonaDataProps } from "../../types";
import { Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import { FormControlLabel } from "@mui/material";
import CreateUpdatePersona from "./CreateUpdatePersona";
import Button, { ButtonProps } from "@mui/material/Button";
import Badge from "@mui/material/Badge";
export interface PersonaCardProps {
  name: string;
  description: string;
  persona_id: string;
  isDefault: boolean;
  setPersonas: (personas: any) => void;
  setUserAssets: (userAssets: any) => void;
}
export default function PersonaCard({
  name,
  description,
  persona_id,
  isDefault,
  setPersonas,
  setUserAssets,
}: PersonaCardProps) {
  const [persona, setPersona] = useState<PersonaDataProps>({
    name: name,
    description: description,
    persona_id: persona_id,
    default: isDefault,
  });
  const [showCreatePersona, setShowCreatePersona] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseForm = () => {
    setShowCreatePersona(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { user, isAuthenticated } = useAuth0();
  const handleDelete = () => {
    console.log("Deleting persona with ID:", persona_id);
    if (!isAuthenticated || !user) {
      throw new Error("User is not authenticated");
    }
    const deleteChat = async () => {
      const request_body = {
        item: "persona",
        persona_id: persona_id,
        default: isDefault,
        username: user["https://chat.4wall.ai/username"],
      };

      const apiUrl = process.env.REACT_APP_DELETE_API;
      if (!apiUrl) {
        throw new Error("REACT_APP_DELETE_API is not defined");
      }
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(request_body),
        });

        if (response.ok) {
          const data = await response.json();
          setPersonas((prevPersonas: any) =>
            prevPersonas.filter(
              (persona: PersonaDataProps) => persona.persona_id !== persona_id
            )
          );
          setUserAssets((prevUserAssets: any) => ({
            ...prevUserAssets,
            personas: prevUserAssets.personas.filter(
              (persona: PersonaDataProps) => persona.persona_id !== persona_id
            ),
          }));
        } else {
          throw new Error(`Server responded with ${response.status}`);
        }
      } catch (error) {
        console.error("There was an error deleting the persona:", error);
      }
    };

    deleteChat();
    setOpen(false);
  };

  return (
    <div className="persona-card">
      <div style={{ display: "flex", margin: "auto" }}>
        <div className="persona-card-name-area">
          {persona.name}{" "}
          {isDefault && (
            <Badge color="secondary" badgeContent={"ACTIVE"}></Badge>
          )}
        </div>
        <div className="persona-card-utils">
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => setShowCreatePersona(true)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="error"
            onClick={handleClickOpen}
          >
            <DeleteIcon />
          </IconButton>
          <Dialog className="delete-popup" open={open} onClose={handleClose}>
            <DialogTitle>{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText color={"var(--primary-text-color"}>
                Are you sure you want to delete this character?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleDelete} color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <CreateUpdatePersona
          persona={persona}
          showCreatePersona={showCreatePersona}
          setShowCreatePersona={setShowCreatePersona}
          handleCloseForm={handleCloseForm}
          setPersona={setPersona}
          setPersonas={setPersonas}
          setUserAssets={setUserAssets}
        />
      </div>
    </div>
  );
}
