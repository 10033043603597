import React, { useState, ChangeEvent } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import useRequest from "../../apis/useRequest";
import { Backdrop, CircularProgress } from "@mui/material";
import { PersonaDataProps, UserAssets } from "../../types";
import { set } from "lodash";
import { Alert, Snackbar } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import {
  createPersona,
  updatePersona,
  getPersona,
  getUserAssets,
} from "../../apis/request";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../../contexts/PlatformContext";

import { Switch } from "@mui/material";

interface CreateUpdatePersonaProps {
  persona: PersonaDataProps;
  handleCloseForm: () => void;
  showCreatePersona: boolean;
  setShowCreatePersona: (showCreatePersona: boolean) => void;
  setPersona: (persona: any) => void;
  setPersonas: (personas: any) => void;
  setUserAssets: (userAssets: any) => void;
}

function CreateUpdatePersona({
  persona,
  handleCloseForm,
  showCreatePersona,
  setShowCreatePersona,
  setPersona,
  setPersonas,
  setUserAssets,
}: CreateUpdatePersonaProps) {
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  // Helper function to open alert
  const showAlert = (message: string, severity: "success" | "error") => {
    setAlertInfo({ open: true, message, severity });
  };

  // Helper function to close alert
  const closeAlert = () => {
    setAlertInfo({ ...alertInfo, open: false });
  };
  const handleChange =
    (prop: keyof PersonaDataProps) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPersona({ ...persona, [prop]: event.target.value });
    };

  const handleDefaultSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPersona({ ...persona, default: event.target.checked });
  };

  const context = React.useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { username } = context;

  const refetchUserAssets = async (username: string) => {
    const userRequest = getUserAssets(username, "false");
    try {
      const response = await makeRequest<UserAssets, any>(userRequest);
      setUserAssets(response);
    } catch (error) {
      console.error("There was an error fetching user assets:", error);
    }
  };
  const makeRequest = useRequest();
  const handleSubmitForm = async () => {
    const userRequest =
      persona.persona_id === ""
        ? createPersona()
        : updatePersona(persona.persona_id);
    const request_body = {
      persona: {
        name: persona.name,
        description: persona.description,
        persona_id: persona.persona_id,
        default: persona.default,
        username: username,
      },
    };
    try {
      const response = await makeRequest<PersonaDataProps, any>(
        userRequest,
        request_body
      );
      showAlert("Persona created/updated successfully!", "success");
      await refetchUserAssets(username);
      setShowCreatePersona(false);
    } catch (error) {
      console.error("There was an error creating/updating the persona:", error);
      showAlert("Failed to create/update persona. Please try again.", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const LoadingOverlay = ({ open }: { open: boolean }) => (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    <div>
      {" "}
      <Dialog
        open={showCreatePersona}
        onClose={handleCloseForm}
        PaperProps={{
          className: "persona-form", // Replace 'yourDesiredColor' with your color
        }}
      >
        <DialogTitle>Create/Update Persona</DialogTitle>

        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                checked={persona.default || persona.persona_id === ""}
                onChange={handleDefaultSwitchChange}
              />
            }
            label="Set as active for chats"
          />
          <h4>Basic Information</h4>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            multiline
            variant="outlined"
            inputProps={{
              maxlength: 20,
            }}
            value={persona.name}
            helperText={`${persona.name.length}/${20}`}
            onChange={handleChange("name")}
            InputLabelProps={{
              className: "persona-form-label",
            }}
            InputProps={{
              className: "persona-form-input",
            }}
          />
          <h4>Provide more details of the persona</h4>
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={8}
            variant="outlined"
            inputProps={{
              maxlength: 750,
            }}
            value={persona.description}
            helperText={`${persona.description.length}/${750}`}
            onChange={handleChange("description")}
            InputLabelProps={{
              className: "persona-form-label",
            }}
            InputProps={{
              className: "persona-form-input",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm} color="error">
            Cancel
          </Button>
          <Button onClick={handleSubmitForm} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alertInfo.open}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert
          onClose={closeAlert}
          severity={alertInfo.severity}
          sx={{ width: "100%" }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
      <LoadingOverlay open={isSubmitting} />
    </div>
  );
}

export default CreateUpdatePersona;
