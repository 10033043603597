import React, { useRef, useState, useEffect, FC, forwardRef } from "react";
import { Character } from "../../types";
import { Swiper as SwiperInstance } from "swiper/types"; // Importing the type for Swiper instance
import CharacterTrending from "../Character/CharacterTrending";
import Skeleton from "@mui/material/Skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/swiper-bundle.css";
interface FeaturedSwiperProps {
  featuredCharacters: Character[];
  handleCharacterSelect: (char: Character) => void;
  name: string;
  loaded: boolean;
}
type ArrowProps = React.HTMLAttributes<HTMLButtonElement>;

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-prev" {...props}>
      &#10094; {/* Left arrow symbol */}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-next" {...props}>
      &#10095; {/* Right arrow symbol */}
    </button>
  )
);

CustomPrevArrow.displayName = "CustomPrevArrow";
CustomNextArrow.displayName = "CustomNextArrow";

function FeaturedSwiper({
  featuredCharacters,
  handleCharacterSelect,
  name,
  loaded,
}: FeaturedSwiperProps) {
  const [slidesPerGroup, setSlidesPerGroup] = useState(1);
  useEffect(() => {
    const updateSlidesPerGroup = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerGroup(2);
      } else if (width < 1024) {
        setSlidesPerGroup(3);
      } else {
        setSlidesPerGroup(5);
      }
    };

    updateSlidesPerGroup();
    window.addEventListener("resize", updateSlidesPerGroup);

    return () => {
      window.removeEventListener("resize", updateSlidesPerGroup);
    };
  }, []);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  return (
    <div style={{ paddingRight: "1rem" }}>
      <div className="featured-list-header">
        <h2>
          <span>{name}</span>
        </h2>
        <label
          style={{
            display: "flex",
            marginLeft: "auto",
            textShadow: "2px 2px 4px #000000",
          }}
        ></label>
      </div>
      <Swiper
        modules={[
          FreeMode,
          Mousewheel,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
        ]}
        spaceBetween={10}
        slidesPerView={"auto"}
        touchRatio={1}
        speed={600}
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
        }}
        freeMode={true}
        slidesPerGroup={slidesPerGroup}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper: SwiperInstance) => {
          if (typeof swiper.params.navigation !== "boolean") {
            // Ensure it's not the boolean 'false'
            (swiper.params.navigation as any).prevEl = prevRef.current;
            (swiper.params.navigation as any).nextEl = nextRef.current;
          }
        }}
        className="mySwiper"
      >
        <CustomPrevArrow ref={prevRef} />
        <CustomNextArrow ref={nextRef} />
        {featuredCharacters.map((char) => (
          <SwiperSlide
            key={char.character_id}
            onClick={() => handleCharacterSelect(char)}
          >
            {loaded ? (
              <CharacterTrending
                character={char}
                key={char.character_id}
                theme={false}
                onClick={() => handleCharacterSelect(char)}
              />
            ) : (
              <Skeleton variant="rectangular" width={210} height={118} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default FeaturedSwiper;
