import React, { useState, useRef, useEffect } from "react";
import { SketchPicker } from "react-color";
import { CharacterState } from "./Create";

interface ColorSelectorProps {
  selectedColor: string;
  setShowColorContainer: React.Dispatch<React.SetStateAction<boolean>>;
  showColorContainer: boolean;
  setCharacter?: React.Dispatch<React.SetStateAction<any>>;
  character?: CharacterState;
  attributeToSet?: string;
  label?: string;
}
function ColorSelector({
  selectedColor,
  setShowColorContainer,
  showColorContainer,
  setCharacter,
  character,
  attributeToSet,
  label,
}: ColorSelectorProps) {
  const colorPickerRef = useRef<HTMLDivElement | null>(null);
  const colorSelectorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showColorContainer]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target as Node) &&
      colorSelectorRef.current &&
      !colorSelectorRef.current.contains(event.target as Node)
    ) {
      setShowColorContainer(false);
    }
  };
  return (
    <div className="color-picker-container">
      {label ? label : <p>Or Choose color</p>}
      <div className="color-selector" ref={colorSelectorRef}>
        <div
          className="color-indicator"
          style={{ backgroundColor: selectedColor }}
          onClick={() => setShowColorContainer(!showColorContainer)}
        ></div>
      </div>
      {showColorContainer && (
        <div className="color-container-overlay" ref={colorPickerRef}>
          <SketchPicker
            color={selectedColor}
            onChangeComplete={(color) => {
              // setShowColorContainer(false);
              if (setCharacter && attributeToSet) {
                setCharacter((character: CharacterState) => ({
                  ...character,
                  [attributeToSet]: color.hex,
                }));
              } else {
                console.warn("no setCharacter");
              }
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ColorSelector;
