import React, { useState, useContext, useEffect, useMemo } from "react";
import Stack from "@mui/material/Stack";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Character } from "../../types";
import Button from "@mui/material/Button";
import { Pagination as MuiPagination } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import Genpopup from "../Misc/Genpopup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExploreSwiper from "../common/ExploreSwiper";
import ExploreGrid from "../common/ExploreGrid";

import "./Landing.css";

type SortOrder = "New" | "Most Popular";

const tags: string[] = [
  "Fantasy",
  "Anime",
  "Celebrities",
  "Comedy",
  "Historical",
  "Horror",
  "Role Play",
  "Sci-Fi",
  "Superheroes",
  "Gaming",
  "LGBTQ+",
  "Original Character",
  "NSFW",
  "Movies & TV",
];

type SortButtonProps = {
  onSortChange: (newSortOrder: SortOrder) => void;
};

function SortButton({ onSortChange }: SortButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>("Most Popular");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (order: SortOrder) => {
    setSortOrder(order);
    onSortChange(order);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="sort-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
        className="sort-by-button"
      >
        Sort by: {sortOrder}
      </Button>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(sortOrder)}
      >
        <MenuItem onClick={() => handleClose("New")}>New</MenuItem>
        <MenuItem onClick={() => handleClose("Most Popular")}>
          Most Popular
        </MenuItem>
      </Menu>
    </div>
  );
}

interface TagsComponentProps {
  onTagsChange: (tags: string[]) => void;
  initialTags: string[];
}

const TagsComponent = ({ onTagsChange, initialTags }: TagsComponentProps) => {
  const [selectedTags, setSelectedTags] = useState(initialTags);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { NSFW } = context;

  const toggleTag = (tag: string) => {
    let updatedTags: string[] = [];
    if (selectedTags.includes(tag)) {
      updatedTags = selectedTags.filter((t: string) => t !== tag);
    } else {
      updatedTags = [...selectedTags, tag];
    }
    setSelectedTags(updatedTags);
    onTagsChange(updatedTags);
  };

  useEffect(() => {
    onTagsChange(selectedTags);
  }, []);

  return (
    <>
      <div className="tags-section">
        {tags.map((tag, index) => {
          if (tag === "NSFW" && !NSFW) return null;
          return (
            <button
              className="tag-button"
              key={index}
              type="button"
              onClick={() => toggleTag(tag)}
              style={{
                backgroundColor: selectedTags.includes(tag)
                  ? "var(--tag-selected-color)"
                  : "var(--tag-bg-color)",
                color: selectedTags.includes(tag) ? "black" : "white",
              }}
            >
              {tag}
            </button>
          );
        })}
      </div>
    </>
  );
};

interface ExploreProps {
  handleCharacterSelect: (character: Character) => void;
  isDynamicTheme: boolean;
}

function Explore({ handleCharacterSelect, isDynamicTheme }: ExploreProps) {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [sortOrder, setSortOrder] = useState<SortOrder>("Most Popular");
  const [cloudSearchQuery, setCloudSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(60);
  const [startIndex, setStartIndex] = useState(0);

  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { NSFW } = context;

  useEffect(() => {
    // setTotalPages(Math.ceil(characters.length / itemsPerPage));
  }, [characters]);

  const charactersToShow = useMemo(() => {
    // const startIndex = (currentPage - 1) * itemsPerPage;
    // const endIndex = startIndex + itemsPerPage;

    let sortedCharacters = characters;
    if (sortOrder === "Most Popular") {
      sortedCharacters = [...characters].sort(
        (a, b) => b.interactions - a.interactions
      );
    } else if (sortOrder === "New") {
      sortedCharacters = [...characters].sort((a, b) => {
        let dateA = new Date(a.created_at);
        let dateB = new Date(b.created_at);
        return dateB.getTime() - dateA.getTime();
      });
    }
    return sortedCharacters;
    // return sortedCharacters.slice(startIndex, endIndex);
  }, [characters, currentPage, sortOrder]);

  const darkTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: localStorage.getItem("darkMode") === "true" ? "dark" : "light",
        },
      }),
    []
  );

  function parseSearchResults(searchResults: any) {
    const hits = searchResults.hits.hit;
    const filteredHits = hits.filter((hit: any) => {
      const { is_deleted, is_public } = hit.fields;
      return is_deleted === "0" && is_public === "1";
    });

    return filteredHits.map((hit: any) => ({
      bio: hit.fields.bio,
      char_message_bg_color: hit.fields.char_message_bg_color,
      char_text_color: hit.fields.char_text_color,
      character_id: hit.fields.character_id,
      chat_background_color: hit.fields.chat_background_color,
      creator: hit.fields.creator,
      user_message_bg_color: hit.fields.user_message_bg_color,
      user_text_color: hit.fields.user_text_color,
      name: hit.fields.name,
      image_url: `https://4thwall-assets.s3.amazonaws.com/${hit.fields.character_id}/display_picture`,
      chat_background_url: `https://4thwall-assets.s3.amazonaws.com/${hit.fields.character_id}/chat_background`,
      interactions: hit.fields.num_interactions,
      num_saves: hit.fields.num_favorites,
      tags: hit.fields.tags,
      created_at: hit.fields.created_at,
    }));
  }

  const handleTagSubmit = (currentTags: string[]): void => {
    if (currentTags.length === 0) {
      setCharacters([]);
      setStartIndex(0);
      return;
    }

    let tagsQuery;
    let newCloudSearchQuery;

    // Encode tags to handle special characters
    const encodedTags = currentTags.map((tag) => encodeURIComponent(tag));

    if (!NSFW) {
      const excludeNsfwQuery = `not (term field=tags 'NSFW')`;
      tagsQuery = encodedTags
        .map((encodedTag) => `(term field=tags '${encodedTag}')`)
        .join(" ");
      newCloudSearchQuery = `q=(and(or ${tagsQuery}) (${excludeNsfwQuery}))&fq=(and is_public:'1' is_deleted:'0')&q.parser=structured`;
    } else {
      tagsQuery = encodedTags
        .map((encodedTag) => `(term field=tags '${encodedTag}')`)
        .join(" ");
      newCloudSearchQuery = `q=(or ${tagsQuery})&fq=(and is_public:'1' is_deleted:'0')&q.parser=structured`;
    }

    setCloudSearchQuery(newCloudSearchQuery);
    fetchInitialCharacters(newCloudSearchQuery);
  };

  const fetchInitialCharacters = (query: string) => {
    const apiUrl = process.env.REACT_APP_SEARCH_API;
    const searchUrl = `${apiUrl}?${query}&start=0&size=${itemsPerPage}&cachebust=${new Date().getTime()}`;
    fetch(searchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const newCharacters = parseSearchResults(data);
        setCharacters(newCharacters);
        // setTotalPages(Math.ceil(newCharacters.length / itemsPerPage));
        setStartIndex(60);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleLoadMoreCharacters = () => {
    const apiUrl = process.env.REACT_APP_SEARCH_API;
    const searchUrl = `${apiUrl}?${cloudSearchQuery}&size=${itemsPerPage}&start=${startIndex}&cachebust=${new Date().getTime()}`;
    fetch(searchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const newCharacters = parseSearchResults(data);
        setCharacters((prevCharacters) => [
          ...prevCharacters,
          ...newCharacters,
        ]);
        setStartIndex((prevStartIndex) => prevStartIndex + itemsPerPage);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <TagsComponent initialTags={["Anime"]} onTagsChange={handleTagSubmit} />
      <SortButton onSortChange={setSortOrder} />
      <ExploreGrid
        characters={charactersToShow}
        onLoadMore={handleLoadMoreCharacters}
        handleCharacterSelect={handleCharacterSelect}
      />
    </div>
  );
}

export default Explore;
