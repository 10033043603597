import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { purple, blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import "./ProfilePage.css";
import { UserAssets } from "../../types";
import CharacterImage from "../Misc/CharacterImage";
import { SavedChatsProps } from "./SavedChats";

export interface ChatCardProps {
  character_id: string;
  chat_id: string;
  name: string;
  last_interaction: string;
  image_url: string;
  setChats: React.Dispatch<React.SetStateAction<SavedChatsProps[]>>;
  setUserAssets: React.Dispatch<React.SetStateAction<UserAssets>>;
}
function ChatCard({
  name,
  last_interaction,
  character_id,
  chat_id,
  image_url,
  setChats,
  setUserAssets,
}: ChatCardProps) {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const ContinueButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    borderRadius: "2rem",
    margin: "0.5rem",
    fontFamily: "Pixelify Sans",
    "&:hover": {
      backgroundColor: purple[700],
    },
  }));
  const StartNewButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    borderRadius: "2rem",
    margin: "0.5rem",
    fontFamily: "Pixelify Sans",
    "&:hover": {
      backgroundColor: blue[700],
    },
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    console.log("Deleting chat with ID:", chat_id);
    const deleteChat = async () => {
      const request_body = {
        item: "chat",
        chat_id: chat_id,
      };

      const apiUrl = process.env.REACT_APP_DELETE_API;
      if (!apiUrl) {
        throw new Error("REACT_APP_DELETE_API is not defined");
      }
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(request_body),
        });

        if (response.ok) {
          const data = await response.json();
          setChats((prevChats) =>
            prevChats.filter((chat) => chat.chat_id !== chat_id)
          );
          setUserAssets((prevUserAssets) => ({
            ...prevUserAssets,
            chats: prevUserAssets.chats.filter(
              (chat) => chat.chat_id !== chat_id
            ),
          }));
        } else {
          throw new Error(`Server responded with ${response.status}`);
        }
      } catch (error) {
        console.error("There was an error fetching the characters:", error);
      }
    };

    deleteChat();
    setOpen(false);
  };

  return (
    <div className="chat-card fastFadeInUp">
      <img src={image_url} alt={name} className="profile-character-image" />
      {/* <CharacterImage
        image_url={image_url}
        name={name}
        className="profile-character-image"
      /> */}
      <div className="chat-card-info">
        <h3>{name}</h3>
        <p
          style={{
            fontSize: "small",
            marginBottom: "1rem",
            fontFamily: "Transducer Regular",
          }}
        >
          {/* Last interaction on{" "} */}
          {new Date(last_interaction).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
        <ContinueButton
          variant="contained"
          color="primary"
          onClick={() => navigate(`/chat/${character_id}/${chat_id}`)}
        >
          Continue
        </ContinueButton>
        <StartNewButton
          variant="contained"
          color="primary"
          onClick={() => navigate(`/chat/${character_id}`)}
        >
          Start New
        </StartNewButton>
      </div>
      <div>
        <IconButton aria-label="delete" color="error" onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </div>
      <Dialog className="delete-popup" open={open} onClose={handleClose}>
        <DialogTitle>{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this chat?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ChatCard;
