import React, {
  useState,
  KeyboardEvent,
  useEffect,
  useContext,
  useRef,
  forwardRef,
} from "react";
import "./Misc.css"; // This will be your CSS file for styling the overlay
import { useNavigate } from "react-router-dom";
import { Character } from "../../types";
import ExploreGrid from "../common/ExploreGrid";
import { CircularProgress } from "@mui/material";
import { PlatformContext } from "../../contexts/PlatformContext";

type ArrowProps = React.HTMLAttributes<HTMLButtonElement>;

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="search-custom-swiper-button-prev" {...props}>
      &#10094; {/* Left arrow symbol */}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="search-custom-swiper-button-next" {...props}>
      &#10095; {/* Right arrow symbol */}
    </button>
  )
);

interface SearchOverlayProps {
  isOpen: boolean;
  onClose: () => void;
}

// Debounce function with TypeScript types
function debounce<F extends (...args: any[]) => any>(
  func: F,
  wait: number
): (...args: Parameters<F>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  return function (...args: Parameters<F>): void {
    const later = () => {
      timeout = null;
      func(...args);
    };
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
  };
}

const SearchOverlay: React.FC<SearchOverlayProps> = ({ isOpen, onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [characters, setCharacters] = useState<Character[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(60);
  const [startIndex, setStartIndex] = useState(0);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { NSFW } = context;

  const overlayRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const handleCharacterSelect = (character: Character) => {
    navigate(`/chat/${character.character_id}`);
    onClose();
  };

  function parseSearchResults(searchResults: any) {
    // Extract the hits from the search results
    const hits = searchResults.hits.hit;

    // Log those that are filtered out for debugging
    const filteredHits = hits.filter((hit: any) => {
      const { is_deleted, is_public, tags } = hit.fields;
      const isDeleted = is_deleted !== "0";
      const isNotPublic = is_public !== "1";
      console.log("NSFW", NSFW);
      console.log(tags);
      const containsNsfw = tags.includes("NSFW");
      console.log(containsNsfw);
      if (isDeleted || isNotPublic || (!NSFW && containsNsfw)) {
        console.log(
          `Character skipped (ID: ${hit.fields.character_id}, Name: ${hit.fields.name}) because is_deleted=${isDeleted}, is_public=${isNotPublic}, and contains NSFW=${containsNsfw}`
        );
        return false;
      }
      return true;
    });

    // Map over the hits array to transform each search hit into a Character object
    const characters = filteredHits.map((hit: any) => {
      const fields = hit.fields;

      return {
        bio: fields.bio,
        char_message_bg_color: fields.char_message_bg_color,
        char_text_color: fields.char_text_color,
        character_id: fields.character_id,
        chat_background_color: fields.chat_background_color,
        creator: fields.creator,
        user_message_bg_color: fields.user_message_bg_color,
        user_text_color: fields.user_text_color,
        name: fields.name,
        image_url: `https://4thwall-assets.s3.amazonaws.com/${fields.character_id}/display_picture`,
        chat_background_url: `https://4thwall-assets.s3.amazonaws.com/${fields.character_id}/chat_background`,
        interactions: fields.num_interactions,
        num_saves: fields.num_favorites,
        tags: fields.tags,

        // ... map all other fields to their respective Character properties
      };
    });

    return characters;
  }

  const debouncedSearch = useRef(
    debounce((searchTerm: string) => {
      if (searchTerm.trim().length > 0) {
        fetchSearchResults(searchTerm.trim());
      }
    }, 300)
  ).current;

  const fetchSearchResults = (value: string) => {
    setIsLoading(true);
    setCharacters([]);

    const apiUrl = `${process.env.REACT_APP_SEARCH_API}?q=${encodeURIComponent(
      value + "*"
    )}&fq=(and is_public:'1' is_deleted:'0')&start=0&size=${itemsPerPage}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const newCharacters = parseSearchResults(data);
        setCharacters(newCharacters);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Search error:", error);
        setIsLoading(false);
      });
  };

  const handleLoadMoreCharacters = () => {
    if (characters.length < itemsPerPage) {
      return;
    }
    const apiUrl = process.env.REACT_APP_SEARCH_API;
    const searchUrl = `${
      process.env.REACT_APP_SEARCH_API
    }?q=${encodeURIComponent(
      searchTerm.trim() + "*"
    )}&fq=(and is_public:'1' is_deleted:'0')&size=${itemsPerPage}&start=${startIndex}&cachebust=${new Date().getTime()}`;
    fetch(searchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const newCharacters = parseSearchResults(data);
        setCharacters((prevCharacters) => [
          ...prevCharacters,
          ...newCharacters,
        ]);
        setStartIndex((prevStartIndex) => prevStartIndex + itemsPerPage);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (isOpen) {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm]);

  if (!isOpen) return null;

  return (
    <div className="search-overlay">
      <div className="search-area" ref={overlayRef}>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          className="main-search-input"
          autoFocus
        />
        <button className="search-close-btn" onClick={onClose}>
          X
        </button>
      </div>
      {isLoading && <CircularProgress />}
      <ExploreGrid
        characters={characters}
        onLoadMore={handleLoadMoreCharacters}
        handleCharacterSelect={handleCharacterSelect}
      />
    </div>
  );
};

export default SearchOverlay;
