import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import ContinueButton from "./ChatCard";
import CharacterImage from "../Misc/CharacterImage";
import { purple, blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import "./ProfilePage.css";
import { SavedBotcastProps } from "./SavedBotcasts";
import { UserAssets } from "../../types";

export interface BotcastCardProps {
  character1_id: string;
  character2_id: string;
  botcast_id: string;
  character1_name: string;
  character2_name: string;
  last_interaction: string;
  character1_image_url: string;
  character2_image_url: string;
  topic: string;
  setBotcasts: React.Dispatch<React.SetStateAction<SavedBotcastProps[]>>;
  setUserAssets: React.Dispatch<React.SetStateAction<UserAssets>>;
}

function BotcastCard({
  character1_id,
  character2_id,
  botcast_id,
  character1_name,
  character2_name,
  last_interaction,
  character1_image_url,
  character2_image_url,
  topic,
  setBotcasts,
  setUserAssets,
}: BotcastCardProps) {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const ContinueButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    borderRadius: "2rem",
    margin: "0.5rem",
    fontFamily: "Pixelify Sans",
    "&:hover": {
      backgroundColor: purple[700],
    },
  }));
  const StartNewButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    borderRadius: "2rem",
    margin: "0.5rem",
    fontFamily: "Pixelify Sans",
    "&:hover": {
      backgroundColor: blue[700],
    },
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    console.log("Deleting botcast with ID:", botcast_id);
    const deleteBotcast = async () => {
      const request_body = {
        item: "botcast",
        botcast_id: botcast_id,
      };
      const apiUrl = process.env.REACT_APP_DELETE_API;
      if (!apiUrl) {
        throw new Error("REACT_APP_DELETE_API is not defined");
      }
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(request_body),
        });

        if (response.ok) {
          const data = await response.json();
          setBotcasts((prevBotcasts) =>
            prevBotcasts.filter((botcast) => botcast.botcast_id !== botcast_id)
          );
          setUserAssets((prevUserAssets) => ({
            ...prevUserAssets,
            botcasts: prevUserAssets.botcasts.filter(
              (botcast) => botcast.botcast_id !== botcast_id
            ),
          }));
        } else {
          throw new Error(`Server responded with ${response.status}`);
        }
      } catch (error) {
        console.error("There was an error fetching the characters:", error);
      }
    };

    deleteBotcast();
    setOpen(false);
  };

  return (
    <div className="botcast-card fastFadeInUp">
      <div className="topic-header">
        <h2>{topic}</h2>
        <IconButton
          style={{ marginLeft: "auto" }}
          aria-label="delete"
          color="error"
          onClick={handleClickOpen}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className="botcast-card-main">
        <div className="botcast-character-card">
          <img src={character1_image_url} alt={character1_name} />
          {/* <CharacterImage
            image_url={character1_image_url}
            name={character1_name}
          /> */}
          <h3>{character1_name}</h3>
        </div>
        &#x1F399;
        <div className="botcast-character-card">
          <CharacterImage
            image_url={character2_image_url}
            name={character2_name}
          />
          <h3>{character2_name}</h3>
        </div>
      </div>
      <Button
        onClick={() =>
          navigate(
            `/botcast/view/${character1_id}/${character2_id}/${botcast_id}`
          )
        }
      >
        View
      </Button>
      <h4
        style={{
          margin: "1rem auto",
          fontSize: "0.8rem",
          fontFamily: "Transducer Regular",
        }}
      >
        Generated on{" "}
        {new Date(last_interaction).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </h4>
      <Dialog className="delete-popup" open={open} onClose={handleClose}>
        <DialogTitle>{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this botcast?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BotcastCard;
