export function isDarkColor(color: string): boolean {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  // Using the luminance formula to find brightness
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness < 128;
}

export function formatMessageText(text: string, isCharMessageDark: boolean) {
  let formattedText = text.replace(/\n/g, "<br>");
  const className = isCharMessageDark ? "lighten-italic" : "darken-italic";
  formattedText = formattedText.replace(
    /\*(.*?)\*/g,
    `<span class='${className}'>$1</span>`
  );
  return formattedText;
}

export function reverseFormatMessageText(htmlText: string): string {
  let unformattedText = htmlText.replace(/<br>/g, "\n");
  // Adjusted to dynamically match the specific <span> tag with either 'darken-italic' or 'lighten-italic' class
  unformattedText = unformattedText.replace(
    /<span class='(darken-italic|lighten-italic)'>(.*?)<\/span>/g,
    "*$2*\n"
  );
  return unformattedText;
}
