import React, { useRef, useState, useEffect, FC, forwardRef } from "react";
import { Character } from "../../types";
import { Swiper as SwiperInstance } from "swiper/types"; // Importing the type for Swiper instance
import { Swiper, SwiperSlide } from "swiper/react";
import { Dialog } from "@mui/material";
import {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  A11y,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/swiper-bundle.css";
import Videobox from "../common/Videobox";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import SocialCard from "../Landing/SocialCard";

type ArrowProps = React.HTMLAttributes<HTMLButtonElement>;

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-prev" {...props}>
      &#10094; {/* Left arrow symbol */}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-next" {...props}>
      &#10095; {/* Right arrow symbol */}
    </button>
  )
);

CustomPrevArrow.displayName = "CustomPrevArrow";
CustomNextArrow.displayName = "CustomNextArrow";

function NewsCarousel({}) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();

  const handleClickRequiringSignUp = (route: string) => {
    if (isAuthenticated) {
      navigate(route);
    } else {
      loginWithRedirect({
        appState: { returnTo: route },
      });
    }
  };
  return (
    <div style={{ paddingRight: "1rem" }}>
      {showVideo && (
        <Dialog
          open={showVideo}
          onClose={() => setShowVideo(false)}
          fullWidth={true}
          maxWidth="md" // Adjust size as needed
          style={{ zIndex: 1300 }} // Ensure it's above other content
        >
          <Videobox />
        </Dialog>
      )}
      <Swiper
        modules={[
          FreeMode,
          Mousewheel,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
        ]}
        style={{ height: "29rem" }}
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides={true}
        loop={true}
        // slidesPerView={"auto"}
        touchRatio={1}
        speed={600}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true,
        }}
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
        }}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
        allowTouchMove={window.innerWidth <= 768} // Disable swiping if not on mobile
        simulateTouch={window.innerWidth <= 768} // Enable swiping if on mobile
      >
        <SwiperSlide>
          <div style={{ display: "flex" }}>
            <SocialCard url="https://discord.gg/EUebsQR5KE" />
            <SocialCard url="https://www.reddit.com/r/4WallAI/" />
            <SocialCard url="https://x.com/4WallAI_" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide chat-characters-slide"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide create-character-slide"
            onClick={() => handleClickRequiringSignUp("/create")}
          ></div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide create-botcast-slide"
            onClick={() => handleClickRequiringSignUp("/botcast/create")}
          ></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide get-featured-slide"></div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default NewsCarousel;
