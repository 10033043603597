import React, { useState, useEffect } from "react";
import { PersonaDataProps } from "../../types";
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress } from "@mui/material";
import CreateUpdatePersona from "./CreateUpdatePersona";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../../contexts/PlatformContext";
import PersonaCard from "./PersonaCard";
import { set } from "lodash";
function Personas() {
  const [showCreatePersona, setShowCreatePersona] = useState(false);
  const [persona, setPersona] = useState<PersonaDataProps>({
    name: "",
    description: "",
    persona_id: "",
    default: false,
  });
  const handleCloseForm = () => {
    setShowCreatePersona(false);
  };
  const handlePersonaCreateUpdate = (persona_id: string) => {
    if (persona_id !== "") {
      console.log("creating new");
      setPersona({
        name: "",
        description: "",
        persona_id: "",
        default: false,
      });
    }

    setShowCreatePersona(true);
  };

  const [personas, setPersonas] = useState<PersonaDataProps[]>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const context = React.useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { userAssets, setUserAssets } = context;
  const { user, isAuthenticated, isLoading } = useAuth0();

  const fetchCharacters = async () => {
    if (isAuthenticated && user) {
      const data = userAssets.personas;
      console.log(data);
      setPersonas(data);
    }
  };
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      window.location.href = "/";
    } else if (!isLoading && isAuthenticated) {
      fetchCharacters();
    }
    setPageLoading(false);
  }, [isLoading, userAssets.characters]);

  if (pageLoading) {
    return (
      <div className="loading-centered">
        {" "}
        <CircularProgress color="inherit" />
      </div>
    );
  }
  return (
    <div className="character-list">
      {/* {botcasts.map((botcast) => ( */}
      <div
        className="create-persona-container"
        onClick={() => handlePersonaCreateUpdate("")}
      >
        <AddIcon fontSize="large" />

        <div>Create a persona</div>
      </div>
      <CreateUpdatePersona
        persona={persona}
        showCreatePersona={showCreatePersona}
        setShowCreatePersona={setShowCreatePersona}
        handleCloseForm={handleCloseForm}
        setPersona={setPersona}
        setPersonas={setPersonas}
        setUserAssets={setUserAssets}
      />
      {personas.map((pers) => (
        <PersonaCard
          key={pers.persona_id}
          persona_id={pers.persona_id}
          name={pers.name}
          description={pers.description}
          isDefault={pers.default}
          setPersonas={setPersonas}
          setUserAssets={setUserAssets}
        />
      ))}
    </div>
  );
}

export default Personas;
