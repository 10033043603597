import React from "react";
import Textarea from "@mui/joy/Textarea";
import InfoBubble from "./InfoBubble";

interface InfoInputProps {
  infoText: string;
  label: string;
  size: number;
  maxLength: number;
  value: string | undefined;
  subText: string;
  onChange: (value: string) => void;
}

const InfoInput: React.FC<InfoInputProps> = ({
  infoText,
  label,
  size,
  maxLength,
  value,
  subText,
  onChange,
}) => {
  return (
    <div style={{ padding: "1.5rem 0rem 1em 0rem" }}>
      <label style={{ display: "flex" }}>
        {label}
        {infoText !== "" && (
          <InfoBubble title="Example Conversations" infoText={infoText} />
        )}
      </label>
      <p
        style={{
          fontFamily: "Transducer Regular",
          fontSize: "0.7rem",
        }}
      >
        {subText}
      </p>
      <Textarea
        className="create-textarea"
        color="neutral"
        minRows={size}
        maxRows={size}
        placeholder=""
        variant="solid"
        value={value}
        onInput={(e) => {
          const target = e.target as HTMLTextAreaElement;
          target.value = target.value.slice(0, maxLength);
        }}
        onChange={(e) => onChange(e.target.value)}
      ></Textarea>
    </div>
  );
};

export default InfoInput;
