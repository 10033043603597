import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";

import { Character, UserAssets, FeaturedLists } from "../types";

export interface PlatformContextState {
  username: string;
  characters: Character[];
  setCharacters: React.Dispatch<React.SetStateAction<Character[]>>;
  userAssets: UserAssets;
  setUserAssets: React.Dispatch<React.SetStateAction<UserAssets>>;
  showChats: boolean;
  setShowChats: React.Dispatch<React.SetStateAction<boolean>>;
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  NSFW: boolean;
  setNSFW: React.Dispatch<React.SetStateAction<boolean>>;
  featuredLists: FeaturedLists;
  isMobile: boolean;
  socialUrls: {
    [key: string]: string;
  };
}
export const PlatformContext = React.createContext<
  PlatformContextState | undefined
>(undefined);
