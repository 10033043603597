import React, { useEffect, useState, useContext, ChangeEvent } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./Create.css";
import { useParams, useNavigate } from "react-router-dom";
import Attributes from "./Attributes";
import ColorSelector from "./ColorSelector";
import { Backdrop, CircularProgress, Switch } from "@mui/material";
import InfoBubble from "./InfoBubble";
import InfoInput from "./InfoInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {
  chubInfo,
  greetingInfo,
  descriptionInfo,
  exampleConvoInfo,
  tagsInfo,
  personalizeInfo,
} from "./infotexts";
import Textarea from "@mui/joy/Textarea";
import { PlatformContext } from "../../contexts/PlatformContext";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import TagPicker from "./TagPicker";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { at, set, update } from "lodash";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export interface CharacterState {
  action: "create" | "update";
  character_id?: string;
  creator: string;
  image: File | string;
  chubJson?: File | undefined;
  name: string;
  personality: string;
  attributes: {
    adventurous: number;
    empathy: number;
    funny: number;
    intelligent: number;
  };
  description: string;
  greeting: string;
  bio: string;
  safeMode: string;
  isPublic: boolean;
  chatBackground: File | string;
  chatBackgroundColor: string;
  characterBackgroundColor: string;
  characterTextColor: string;
  userBackgroundColor: string;
  userTextColor: string;
  tags: string[];
  exampleConvos?: string;
}

interface Tag {
  id: number;
  name: string;
}

interface JsonAttributes {
  name?: string;
  greeting?: string;
  description?: string;
  avatar?: string;
}

function Create() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [imageSrc, setImageSrc] = useState<string>("");
  const [chatBgSrc, setChatBgSrc] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [characterLoading, setCharacterLoading] = useState<boolean>(true);
  const [retrievedCharacterId, setRetrievedCharacterId] = useState<string>("");
  const retrievedCharacterIdRef = React.useRef(retrievedCharacterId);
  const [chubLoading, setChubLoading] = useState<boolean>(false);
  const [showBgColorContainer, setShowBgColorContainer] =
    useState<boolean>(false);
  const [showUserBubbleColorContainer, setShowUserBubbleColorContainer] =
    useState<boolean>(false);
  const [
    showCharacterBubbleColorContainer,
    setShowCharacterBubbleColorContainer,
  ] = useState<boolean>(false);
  const [showUserTextColorContainer, setShowUserTextColorContainer] =
    useState<boolean>(false);
  const [showCharacterTextColorContainer, setShowCharacterTextColorContainer] =
    useState<boolean>(false);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("Platform context not found in Create component");
  }
  const {
    characters,
    setCharacters,
    userAssets,
    setUserAssets,
    darkMode,
    setDarkMode,
  } = context;
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [character, setCharacter] = useState<CharacterState>(
    {} as CharacterState
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileAttributes, setFileAttributes] = useState<JsonAttributes | null>(
    null
  );
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const { user, isLoading, isAuthenticated } = useAuth0();
  const { character_id } = useParams<{ character_id: string }>();

  const tags = [
    "Fantasy",
    "Role Play",
    "Celebrities",
    "Sci-Fi",
    "Historical",
    "Anime",
    "Superheroes",
    "Horror",
    "Comedy",
    "NSFW",
    "Gaming",
    "LGBTQ+",
    "Original Character",
    "Movies & TV",
  ];

  const availableTags = tags.map((tag, index) => ({ id: index, name: tag }));

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode.toString()); // Save to localStorage

    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  const fetchCharacter = async (creator: string) => {
    if (isAuthenticated && user) {
      const request_body = {
        structure: "create-character",
        character_id: character_id,
      };
      const apiUrl = process.env.REACT_APP_RETRIEVE_CHARACTERS_API;
      if (!apiUrl) {
        throw new Error("API URL not defined in environment variables");
      }
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(request_body),
        });

        if (response.ok) {
          const data = await response.json();
          let parsedTags = JSON.parse(data.tags);
          if (data.creator !== user["https://chat.4wall.ai/username"]) {
            console.log("not authorized to edit this character");
            navigate("/");
          }
          setCharacter({
            action: "update",
            character_id: character_id,
            creator: creator,
            image: data.image,
            name: data.name,
            personality: "",
            attributes: data.attributes,
            description: data.description,
            greeting: data.greeting,
            exampleConvos: data.exampleConvos,
            bio: data.bio,
            safeMode: data.safeMode,

            isPublic: data.isPublic, //=== "true" ? true : false,
            chatBackground: data.chatBackground,
            chatBackgroundColor: data.chatBackgroundColor,
            characterBackgroundColor: data.characterBackgroundColor,
            characterTextColor: data.characterTextColor,
            userBackgroundColor: data.userBackgroundColor,
            userTextColor: data.userTextColor,
            tags: parsedTags,
          });
          if (parsedTags) {
            setSelectedTags(
              parsedTags.map((tag: string, index: number) => {
                return {
                  id: index,
                  name: tag,
                };
              })
            );
          }

          setCharacterLoading(false);
          return data;
        } else {
          throw new Error(`Server responded with ${response.status}`);
        }
      } catch (error) {
        console.error("There was an error fetching the characters:", error);
      }
    }
  };

  useEffect(() => {
    let creator = "bhupendrajogi";
    if (!isLoading && !isAuthenticated) {
      window.location.href = "/";
    } else if (!isLoading && isAuthenticated) {
      if (user && user["https://chat.4wall.ai/username"]) {
        creator = user["https://chat.4wall.ai/username"];
      }
      setCharacter({
        action: "create",
        character_id: undefined,
        creator: creator,
        image: "",
        name: "",
        personality: "",
        attributes: {
          adventurous: 0,
          empathy: 0,
          funny: 0,
          intelligent: 0,
        },
        description: "",
        greeting: "",
        bio: "",
        safeMode: "false",
        isPublic: true,
        chatBackground: "",
        chatBackgroundColor: !darkMode ? "#f2f2f2" : "#333",
        characterBackgroundColor: "#535252",
        characterTextColor: "#D8D7D6",
        userBackgroundColor: "#000000",
        userTextColor: "#ffffff",
        tags: [],
      });
      if (character_id != undefined) {
        const character = fetchCharacter(creator);

        character.then((data) => {
          if (data && typeof data.image === "string") {
            setImageSrc(data.image);
            setChatBgSrc(data.chatBackground);
          }
        });
      } else {
        setCharacterLoading(false);
      }

      setPageLoading(false);
    }
  }, [isLoading]);
  const sliders = ["Curious", "Creative", "Funny", "Intelligent"];

  const handleNext = () => {
    setCurrentPage(2);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (character.name === "" || character.description === "") {
        setIsSubmitting(false);
        alert("Your character needs to have a name and description!");
        return;
      }

      const formData = new FormData();

      // Iterate over each property of the character object
      for (const [key, value] of Object.entries(character)) {
        if (value instanceof Object && !(value instanceof File)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
      const apiUrl = process.env.REACT_APP_CREATE_OR_UPDATE_API;
      if (!apiUrl) {
        throw new Error("API URL not defined in environment variables");
      }

      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData, // Use the FormData object here
        // No need to set the Content-Type header, the browser will set it automatically
      });
      // Check if the response is okay
      if (response.status === 400) {
        response.json().then((data) => {
          setIsSubmitting(false);
          alert(data); // Shows the error message in an alert
        });
      } else if (!response.ok) {
        setIsSubmitting(false);
        alert("Character create/update failed. Please try again later.");
        throw new Error(
          `Server responded with ${response.status}: ${response.statusText}`
        );
      }
      const data = await response.json();
      if (data.mod_status !== "Approved") {
        alert(data.mod_status);
      } else {
        setRetrievedCharacterId(data.character_id);
        setShowSuccessPopup(true); // Show the success popup
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error("There was an error:", error);
    }
  };

  const handleBack = () => {
    if (currentPage === 1) {
      // Redirect to home
      navigate("/");
    } else if (currentPage === 2) {
      setCurrentPage(1);
    }
  };

  if (pageLoading || characterLoading) {
    return (
      <div className="loading-centered">
        {" "}
        <CircularProgress color="inherit" />
      </div>
    );
  }

  const LoadingOverlay = ({ open }: { open: boolean }) => (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  interface PopupProps {
    open: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
  }

  const Popup = ({ open, onClose, title, children }: PopupProps) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className="create-popup-title">{title}</DialogTitle>
        <DialogContent className="create-popup-content">
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  interface ChubUploadProps {
    character: CharacterState;
    setCharacter: (character: CharacterState) => void;
  }

  function ChubUpload({ character, setCharacter }: ChubUploadProps) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileAttributes, setFileAttributes] = useState<JsonAttributes | null>(
      null
    );

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        setSelectedFile(file);
        setChubLoading(true);
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            const text = e.target?.result;
            const json = JSON.parse(text as string);
            const jsonData = json.data ? json.data : json;
            const attributes = {
              name: jsonData.name,
              greeting: jsonData.first_mes,
              description: jsonData.description,
              personality: jsonData.personality,
              avatar: jsonData.avatar,
              tags: jsonData.tags,
            };
            let imageFile: File | string = "";
            if (attributes.avatar) {
              try {
                const response = await fetch(attributes.avatar);
                const blob = await response.blob();
                imageFile = new File([blob], "avatar.png", {
                  type: "image/png",
                });
                setImageSrc(URL.createObjectURL(imageFile));
              } catch (imgError) {
                console.error("Error fetching the image:", imgError);
              }
            }
            const validTags = tags.filter((value) =>
              attributes.tags.includes(value)
            );

            setSelectedTags(
              validTags.map((tag: string, index: number) => {
                return {
                  id: index,
                  name: tag,
                };
              })
            );
            const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB in bytes

            if (file.size > maxSizeInBytes) {
              alert(
                "The file is too large. Please select a file smaller than 8 MB."
              );
              return;
            }
            setCharacter({
              ...character,
              name: attributes?.name || "",
              greeting: attributes?.greeting || "",
              description:
                attributes?.description + attributes.personality || "",
              attributes: {
                adventurous: 50,
                empathy: 50,
                funny: 50,
                intelligent: 50,
              },
              tags: validTags,
              image: imageFile,
              chubJson: event.target.files ? event.target.files[0] : undefined,
            });

            setFileAttributes(attributes);
          } catch (error) {
            console.error("Error parsing JSON:", error);
            alert("Sorry, this file could not be parsed.");
          } finally {
            setChubLoading(false);
          }
        };
        reader.readAsText(file);
      }
    };

    const handleUpload = () => {
      if (!selectedFile) {
        alert("Please select a file to upload.");
        return;
      }
      // console.log("Uploading:", selectedFile);
      // console.log("Extracted Attributes:", fileAttributes);
      // Reset after upload
      setSelectedFile(null);
      setFileAttributes(null);
    };

    return (
      <div className="section">
        <div className="image-upload">
          <label style={{ display: "flex", fontSize: "0.8rem" }}>
            Upload JSON from Chub <FileUploadIcon />
            <InfoBubble title="Character Description" infoText={chubInfo} />
          </label>
          <Button
            component="label"
            className="chub-input"
            variant="outlined"
            sx={{
              color: "var(--primary-text-color)",
              border: "1px solid var(--primary-text-color)",
            }}
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <VisuallyHiddenInput onChange={handleFileChange} type="file" />
          </Button>
          {selectedFile && <p>Selected File: {selectedFile.name}</p>}
        </div>
      </div>
    );
  }

  const handlePopupClose = () => {
    setShowSuccessPopup(false);
    navigate(`/chat/${retrievedCharacterId}`, {
      state: { character: character },
    });
  };
  const handleSwitchPublic = () => {
    setCharacter({ ...character, isPublic: !character.isPublic });
  };

  return (
    <div className="create-page">
      <ArrowBackIosNewRoundedIcon
        onClick={handleBack}
        className="create-back-arrow"
      />
      <h1 className="heading">Character Creator</h1>

      <div className="create-container">
        {currentPage === 1 ? (
          <div className="page-one">
            <h2 className="subheading">
              Character Details{" "}
              {chubLoading && (
                <CircularProgress
                  style={{ color: "var(--primary-text-color)" }}
                />
              )}{" "}
            </h2>
            {/* Image Upload */}
            <div className="section">
              <div className="image-upload">
                <label htmlFor="imageInput">
                  <div className="image-upload-box">
                    {character.image ? (
                      <img src={imageSrc} alt="Uploaded character" />
                    ) : (
                      <span>Upload Image</span>
                    )}
                  </div>
                </label>
                <input
                  id="imageInput"
                  type="file"
                  onChange={(e) => {
                    if (!e.target.files) {
                      return;
                    }
                    const file = e.target.files[0];

                    // Define the maximum file size limit (e.g., 5 MB)
                    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB in bytes

                    if (file.size > maxSizeInBytes) {
                      alert(
                        "The file is too large. Please select a file smaller than 8 MB."
                      );
                      return;
                    }
                    setCharacter({ ...character, image: e.target.files[0] });
                    setImageSrc(URL.createObjectURL(e.target.files[0]));
                  }}
                  style={{ display: "none" }}
                  accept="image/*"
                />
                <p
                  style={{
                    fontFamily: "Transducer Regular",
                    fontSize: "0.7rem",
                  }}
                >
                  Please upload your character's display image or gif. Only JPG,
                  JPEG, PNG, GIF formats are accepted. File size should be less
                  than 5MB.
                </p>
              </div>

              <div className="name-mbti">
                {/* Name Input */}
                <ChubUpload character={character} setCharacter={setCharacter} />
                <InfoInput
                  infoText={""}
                  label="Name"
                  size={1}
                  maxLength={30}
                  value={character.name}
                  subText="Name your character"
                  onChange={(value) =>
                    setCharacter({ ...character, name: value })
                  }
                />
                <InfoInput
                  infoText={""}
                  label="Character Bio"
                  size={4}
                  maxLength={50}
                  value={character.bio}
                  subText="Summarize your character in 10 words or less for their character card."
                  onChange={(value) =>
                    setCharacter({ ...character, bio: value })
                  }
                />
              </div>
            </div>
            {/* Character Bio */}
            <InfoInput
              infoText={descriptionInfo}
              label="Character Description"
              size={7}
              maxLength={5000}
              value={character.description}
              subText="Describe any unique traits, quirks, or characteristics to personalize your character."
              onChange={(value) =>
                setCharacter({ ...character, description: value })
              }
            />
            <InfoInput
              infoText={greetingInfo}
              label="Greeting"
              size={4}
              maxLength={500}
              value={character.greeting}
              subText="Specify a greeting message for your character"
              onChange={(value) =>
                setCharacter({ ...character, greeting: value })
              }
            />
            <InfoInput
              infoText={exampleConvoInfo}
              label="Example Conversations"
              size={10}
              maxLength={6000}
              value={character.exampleConvos}
              subText="Specify example conversations for your character"
              onChange={(value) =>
                setCharacter({ ...character, exampleConvos: value })
              }
            />
            <div>
              <label style={{ display: "flex" }}>
                Tags
                <InfoBubble title="Tags" infoText={tagsInfo} />
              </label>
              <TagPicker
                availableTags={availableTags}
                selectedTags={selectedTags}
                onChange={(tags: Tag[]) => {
                  setSelectedTags(tags);
                  setCharacter({
                    ...character,
                    tags: tags.map((tag) => tag.name),
                  });
                }}
              />
            </div>
            {/* Attributes Sliders */}
            <Attributes character={character} setCharacter={setCharacter} />
            <div className="first-footer">
              {/* Safe Mode */}
              <FormControlLabel
                control={
                  <Switch
                    checked={character.safeMode === "true"}
                    onChange={(e) =>
                      setCharacter({
                        ...character,
                        safeMode: String(e.target.checked),
                      })
                    }
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Safe Mode"
                style={{ color: "var(--primary-text-color)" }} // replace "desiredColor" with the color you want
              />
              {/* <div className="safe-mode">
                <p>Safe Mode</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setCharacter({
                        ...character,
                        safeMode: String(e.target.checked),
                      })
                    }
                    checked={character.safeMode === "true"}
                  />
                  <span className="slider round"></span>
                </label>
              </div> */}

              <Button className="generic-button" onClick={handleNext}>
                NEXT
              </Button>
            </div>
          </div>
        ) : (
          <div className="page-two">
            <h2 className="subheading">Chat Details</h2>
            {/* Background Color Selection */}
            <div className="section">
              {/* Upload Background Image */}
              <div className="image-upload">
                <label htmlFor="imageBgInput">
                  <div
                    className="image-upload-box"
                    style={{ backgroundColor: character.chatBackgroundColor }}
                  >
                    {character.chatBackground ? (
                      <img
                        // src={URL.createObjectURL(character.chatBackground)}
                        src={chatBgSrc}
                        alt="Uploaded chat background"
                      />
                    ) : (
                      <span style={{ color: "var(--pr)" }}>
                        Upload Chat Background
                      </span>
                    )}
                  </div>
                  <p
                    style={{
                      fontFamily: "Transducer Regular",
                      fontSize: "0.7rem",
                    }}
                  >
                    Please upload your character's chat background image or gif.
                    Only JPG, JPEG, PNG, GIF formats are accepted. File size
                    should be less than 5MB.
                  </p>
                </label>
                <input
                  id="imageBgInput"
                  type="file"
                  onChange={(e) => {
                    if (!e.target.files) {
                      return;
                    }
                    const file = e.target.files[0];

                    // Define the maximum file size limit (e.g., 5 MB)
                    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB in bytes

                    if (file.size > maxSizeInBytes) {
                      alert(
                        "The file is too large. Please select a file smaller than 5 MB."
                      );
                      return;
                    }
                    setCharacter({
                      ...character,
                      chatBackground: e.target.files[0],
                    });
                    setChatBgSrc(URL.createObjectURL(e.target.files[0]));
                  }}
                  style={{ display: "none" }}
                  accept="image/*"
                />

                <div className="bg-color-picker-container">
                  <ColorSelector
                    selectedColor={character.chatBackgroundColor}
                    setShowColorContainer={setShowBgColorContainer}
                    showColorContainer={showBgColorContainer}
                    setCharacter={setCharacter}
                    character={character}
                    attributeToSet="chatBackgroundColor"
                  />
                </div>
              </div>

              {/* Message Bubbles Colors & Preview */}
              <div className="chatmod-container">
                <h2 style={{ display: "flex" }}>
                  Message Bubbles{" "}
                  <InfoBubble
                    title="Message Customization"
                    infoText={personalizeInfo}
                  />
                </h2>
                <div
                  className="chat-preview"
                  style={{
                    backgroundColor: character.chatBackgroundColor,
                  }}
                >
                  <div className="character-chat-mod">
                    <div
                      className="chat-bubble character-bubble"
                      style={{
                        backgroundColor: character.characterBackgroundColor,
                        color: character.characterTextColor,
                      }}
                    >
                      Hi User!
                    </div>
                    <br></br>
                    <ColorSelector
                      selectedColor={character.characterBackgroundColor}
                      setShowColorContainer={
                        setShowCharacterBubbleColorContainer
                      }
                      showColorContainer={showCharacterBubbleColorContainer}
                      setCharacter={setCharacter}
                      character={character}
                      attributeToSet="characterBackgroundColor"
                      label="Character Bubble Color"
                    />
                    <ColorSelector
                      selectedColor={character.characterTextColor}
                      setShowColorContainer={setShowCharacterTextColorContainer}
                      showColorContainer={showCharacterTextColorContainer}
                      setCharacter={setCharacter}
                      character={character}
                      attributeToSet="characterTextColor"
                      label="Character Text Color"
                    />
                  </div>
                  <div className="user-chat-mod">
                    <div
                      className="chat-bubble user-bubble"
                      style={{
                        backgroundColor: character.userBackgroundColor,
                        color: character.userTextColor,
                      }}
                    >
                      Hi Character!
                    </div>
                    <br></br>
                    <ColorSelector
                      selectedColor={character.userBackgroundColor}
                      setShowColorContainer={setShowUserBubbleColorContainer}
                      showColorContainer={showUserBubbleColorContainer}
                      setCharacter={setCharacter}
                      character={character}
                      attributeToSet="userBackgroundColor"
                      label="User Bubble Color"
                    />
                    <ColorSelector
                      selectedColor={character.userTextColor}
                      setShowColorContainer={setShowUserTextColorContainer}
                      showColorContainer={showUserTextColorContainer}
                      setCharacter={setCharacter}
                      character={character}
                      attributeToSet="userTextColor"
                      label="User Text Color"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="private-switch">
              <label style={{ display: "flex" }}>
                Private{" "}
                <InfoBubble
                  title="Make your characters private"
                  infoText={
                    "Private characters are visibile only on your profile and only you can chat or create a botcast with them."
                  }
                />
              </label>
              <Switch
                checked={!character.isPublic}
                onChange={handleSwitchPublic}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            <Button
              disabled={submitDisabled}
              className="generic-button"
              onClick={handleSubmit}
              style={{
                margin: "auto",
              }}
            >
              SUBMIT
            </Button>

            <Popup
              open={showSuccessPopup}
              onClose={handlePopupClose}
              title="Yay"
            >
              Character successfully created/updated !
            </Popup>
          </div>
        )}
      </div>
      <LoadingOverlay open={isSubmitting} />
    </div>
  );
}

export default Create;
