import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { BotcastProp } from "../../types";
import ChatCard from "./ChatCard";
import Botcast from "../Botcasts/Botcast";
import BotcastCard from "./BotcastCard";

export interface SavedBotcastProps {
  character1_id: string;
  character2_id: string;
  botcast_id: string;
  character1_name: string;
  character2_name: string;
  character1_image_url: string;
  character2_image_url: string;
  last_interaction: string;
  topic: string;
  is_private: boolean;
}

function SavedBotcasts() {
  const context = useContext(PlatformContext);
  const [botcasts, setBotcasts] = useState<SavedBotcastProps[]>([]);
  const { user, isAuthenticated, isLoading } = useAuth0();
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { characters, setCharacters, userAssets, setUserAssets } = context;

  // Function to add name and image_url to chatData based on character_id
  function enrichChatDataWithCharacterInfo(
    botcastData: BotcastProp
  ): SavedBotcastProps {
    const character1 = characters.find(
      (c) => c.character_id === botcastData.character1_id
    );
    const character2 = characters.find(
      (c) => c.character_id === botcastData.character2_id
    );
    if (character1) {
      botcastData.character1_name = character1.name;
      botcastData.character1_image_url = character1.image_url;
    } else {
      console.error("Character not found for the given character_id");
    }

    if (character2) {
      botcastData.character2_name = character2.name;
      botcastData.character2_image_url = character2.image_url;
    } else {
      console.error("Character not found for the given character_id");
    }
    return botcastData;
  }

  const fetchBotcasts = async () => {
    if (isAuthenticated && user) {
      const data = userAssets.botcasts.map((item: SavedBotcastProps) =>
        enrichChatDataWithCharacterInfo(item)
      );
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.last_interaction).getTime() -
          new Date(a.last_interaction).getTime()
      );
      setBotcasts(data);
    }
  };
  useEffect(() => {
    fetchBotcasts();
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="character-list">
      {botcasts.map((botcast) => (
        <div>
          <BotcastCard
            key={botcast.botcast_id}
            {...botcast}
            setBotcasts={setBotcasts}
            setUserAssets={setUserAssets}
          />
        </div>
      ))}
    </div>
  );
}

export default SavedBotcasts;
