import React, { useState, useContext, useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../Header";
import "./ProfilePage.css";
import Footer from "../Footer";
import CreatedCharacters from "./CreatedCharacters";
import { useLocation, useParams } from "react-router-dom";
import SavedChats from "./SavedChats";
import SavedBotcasts from "./SavedBotcasts";
import Personas from "./Personas";
import { HeaderCharacterProps } from "../Header";
import Logout from "../Auth/Logout";
import { Light } from "@mui/icons-material";
import { PlatformContext } from "../../contexts/PlatformContext";
import { CircularProgress } from "@mui/material";
import Switch from "@mui/material/Switch";
import ArrowBackIosNewRounded from "@mui/icons-material/ArrowBackIosNewRounded";
import FormControlLabel from "@mui/material/FormControlLabel";

type Tab = "characters" | "chats" | "botcasts" | "personas";

function useOtherProfileCheck(user: any, isAuthenticated: any) {
  const { username } = useParams();
  return useMemo(() => {
    if (user && isAuthenticated) {
      return user["https://chat.4wall.ai/username"] !== username;
    }
    return false;
  }, [user, isAuthenticated, username]);
}

function ProfilePage() {
  const { user, isLoading, isAuthenticated } = useAuth0();
  const isOther = useOtherProfileCheck(user, isAuthenticated);
  const [activeTab, setActiveTab] = useState<Tab>("characters");
  const location = useLocation();
  const { username, user_id } = useParams();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const {
    characters,
    setCharacters,
    userAssets,
    showChats,
    darkMode,
    setDarkMode,
    NSFW,
    setNSFW,
  } = context;

  if (isLoading) {
    return (
      <div className="loading-centered">
        {" "}
        <CircularProgress color="inherit" />
      </div>
    );
  }
  if (!isAuthenticated) {
    window.location.href = "/";
    return null;
  }
  const handleNSFWSwitch = () => {
    setNSFW(!NSFW);
  };

  const renderTabContent = (isOther: boolean) => {
    switch (activeTab) {
      case "characters":
        return (
          <CreatedCharacters
            isOther={isOther}
            otherUsername={username ? username : ""}
          />
        );
      case "chats":
        return <SavedChats />;
      case "botcasts":
        return <SavedBotcasts />;
      case "personas":
        return <Personas />;
      default:
        return null;
    }
  };

  return (
    <div className="background">
      <div className="profile-page">
        <ArrowBackIosNewRounded
          className="create-back-arrow"
          onClick={() => window.history.back()}
        ></ArrowBackIosNewRounded>
        <div className="profile-header">
          {!isOther && <img src={user?.picture} alt={user?.name} />}

          <div className="profile-user-info">
            <div className="profile-header-username">
              @
              {isOther
                ? username
                : user
                ? user["https://chat.4wall.ai/username"]
                : "@bhupendrajogi"}
            </div>

            {!isOther && (
              <>
                <div className="full-name">
                  {user
                    ? user["https://chat.4wall.ai/first_name"]
                    : "Bhupendra"}{" "}
                  {user ? user["https://chat.4wall.ai/last_name"] : "Jogi"}
                </div>

                <div className="profile-subinfo">
                  {user ? user.email : <></>}
                </div>
                {isAuthenticated && <Logout />}
                <FormControlLabel
                  control={
                    <Switch
                      checked={NSFW}
                      onChange={handleNSFWSwitch}
                      color="primary"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="NSFW"
                  style={{ color: "var(--primary-text-color)" }} // replace "desiredColor" with the color you want
                />
              </>
            )}
          </div>
        </div>
        <div className="tabs">
          {["characters", "chats", "botcasts", "personas"].map((tab) =>
            !isOther || tab === "characters" ? (
              <button
                key={tab}
                className={activeTab === tab ? "active-tab" : ""}
                onClick={() => setActiveTab(tab as Tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ) : null
          )}
        </div>
        <div className="tab-content">{renderTabContent(isOther)}</div>
      </div>
    </div>
  );
}

export default ProfilePage;
