import React, { useState, ChangeEvent } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Backdrop, CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const UsernameCollect: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const {
    user,
    getIdTokenClaims,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
  } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const appState = location.state as { returnTo?: string };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedUsername = event.target.value.replace(/\s+/g, "");

    setUsername(updatedUsername);
  };

  const handleUsernameSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (!user) {
      return;
    }
    setLoading(true);
    console.log(user);
    const request_body = {
      action: "social-create",
      username: username,
      user_id: user["https://chat.4wall.ai/user_id"],
      email: user.email,
      first_name: "",
      last_name: "",
      created_characters: [],
      favorited_characters: [],
      is_deleted: false,
    };
    const apiUrl = process.env.REACT_APP_AWS_USER_API;
    if (!apiUrl) {
      throw new Error("API URL not defined in environment variables");
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(request_body),
      });
      // console.log("sending");
      // console.log(appState);

      if (response.ok) {
        logout();
        loginWithRedirect({
          appState: {
            returnTo: appState?.returnTo || "/",
          },
        });
        console.log("Username set successfully");
      } else {
        if (response.status === 400) {
          setError("Username already taken. Please try a different one.");
        } else {
          setError("An error occurred. Please try again.");
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("There was an error choosing the username:", error);
      setError("Failed to connect to the server.");
      setLoading(false);
    }
  };
  const isContinueDisabled = username.trim() === "";

  const LoadingOverlay = ({ open }: { open: boolean }) => (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    <div className="background">
      <div className="username-entry">
        <LoadingOverlay open={loading} />
        {error && <Alert severity="error">{error}</Alert>}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <h1 style={{ color: "var(--primary-text-color)" }}>Welcome!</h1>
          <p style={{ color: "var(--primary-text-color)" }}>
            You are almost ready to break the fourth wall!
          </p>
          <h2 style={{ color: "var(--primary-text-color)" }}>
            Please enter a username.
          </h2>
          <Box
            component="form"
            onSubmit={handleUsernameSubmit}
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            noValidate
            autoComplete="off"
          >
            <TextField value={username} onChange={handleInputChange} />
            <Button
              type="submit"
              variant="contained"
              disabled={isContinueDisabled}
              // onClick={handleUsernameSubmit}
              className={!isContinueDisabled ? "continue-username" : ""}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default UsernameCollect;
