import React, { FC } from "react";
import { useAuth0, LogoutOptions } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import {
  AwesomeButton,
  AwesomeButtonProgress,
  AwesomeButtonSocial,
} from "react-awesome-button";
import "./Auth.css";

const Logout: FC = () => {
  const { logout } = useAuth0();

  return (
    // <button
    //   className="logout-button"
    //   onClick={() =>
    //     logout({ logoutParams: { returnTo: window.location.origin } })
    //   }
    // >
    //   Log Out
    // </button>
    <>
      <Button
        className="logout-button"
        variant="contained"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      >
        Log Out
      </Button>
    </>
  );
};

export default Logout;
