export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum RequestName {
  GET_USER_ASSETS = "GET_USER_ASSETS",
  UPDATE_PERSONA = "UPDATE_PERSONA",
  GET_PERSONA = "GET_PERSONA",
  CREATE_PERSONA = "CREATE_PERSONA",
  GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES",
  GET_BOTCAST_MESSAGES = "GET_BOTCAST_MESSAGES",
  GET_FEATURED_CHARACTERS = "GET_FEATURED_CHARACTERS",
  UPDATE_MESSAGE = "UPDATE_MESSAGE",
  DELETE_MESSAGE = "DELETE_MESSAGE",
  GET_CHARACTER = "GET_CHARACTER",
}

export type RequestDefinition = {
  name: RequestName;
  path: string;
  method: RequestMethod;
  queryParams?: { [key: string]: string | number };
  shouldChangeContext: boolean;
  stubAlways?: boolean;
};
