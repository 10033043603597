import React, {
  useState,
  useEffect,
  FC,
  useContext,
  useRef,
  RefObject,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Character } from "../../types";
import { useAuth0 } from "@auth0/auth0-react";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import { PromptStyle } from "../Chat/ChatMode";
import { useTheme } from "@emotion/react";
import { Alert, Snackbar } from "@mui/material";
import { Button } from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import BotcastSharePopup from "./BotcastSharePopup";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { FormControlLabel } from "@mui/material";
import { Switch } from "@mui/material";
import { track } from "@vercel/analytics";
import { Warning } from "@mui/icons-material";
interface Message {
  sender: "char1" | "char2";
  text: string;
}

function Botcast() {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const empty_character = {
    character_id: "",
    name: "",
    image_url: "",
    creator: "",
    bio: "",
    char_message_bg_color: "",
    user_message_bg_color: "",
    char_text_color: "",
    user_text_color: "",
    is_public: true,
    chat_background_color: "",
    chat_background_url: "",
    interactions: 0,
    created_at: "",
    num_saves: 0,
  };

  const { characters, setCharacters } = context;
  const [sessionId, setSessionId] = useState<string>("");
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [character1, setCharacter1] = useState<Character>(empty_character);
  const [character2, setCharacter2] = useState<Character>(empty_character);
  const [charactersLoading, setCharactersLoading] = useState<boolean>(true);

  const [username, setUsername] = useState<string>("N/A");
  const usernameRef = useRef(username);

  const [botCharacter, setBotCharacter] = useState<string>("second"); // Character acting as bot in current turn
  const [message, setMessage] = useState<string>("seed");

  const [messages, setMessages] = useState<Message[]>([]);
  const messagesRef = useRef(messages);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [userIsOwner, setUserIsOwner] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(true);
  const [topic, setTopic] = useState<string>("");
  const topicRef = useRef(topic);
  const [isPrivateBotcast, setIsPrivateBotcast] = useState<boolean>(false);

  const [showTopic, setShowTopic] = useState<boolean>(false);
  const [userIsInteracting, setUserIsInteracting] = useState(false);
  const [conversationActive, setConversationActive] = useState<boolean>(false);

  const [activeButtons, setActiveButtons] = useState<{ [key: number]: string }>(
    {}
  );
  const [botcastId, setBotcastId] = useState<string>("");
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  // Helper function to open alert
  const showAlert = (message: string, severity: "success" | "error") => {
    setAlertInfo({ open: true, message, severity });
  };

  // Helper function to close alert
  const closeAlert = () => {
    setAlertInfo({ ...alertInfo, open: false });
  };

  const botcastIdRef = useRef(botcastId);
  const location = useLocation();
  const { character_id1, character_id2, botcast_id } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();

  const navigate = useNavigate();

  const fetchBotcast = async () => {
    const request_body = {
      structure: "botcast-existing",
      botcast_id: botcast_id,
    };
    const apiUrl = process.env.REACT_APP_RETRIEVE_MISC_API;
    if (!apiUrl) {
      throw new Error("API URL not defined in environment variables");
    }
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(request_body),
      });
      if (response.ok) {
        const data = await response.json();
        setMessages(data.ui_context);
        setTopic(data.topic);
        setShowTopic(true);
        setIsPrivateBotcast(data.is_private);
        if (user) {
          if (data.botcast_owner === user["https://chat.4wall.ai/username"]) {
            console.log("User is owner");
            setUserIsOwner(true);
          } else {
            console.log("User is not owner");
          }
        } else {
          console.log("User is not authenticated. A viewer has arrived");
        }
      }
    } catch (error) {
      console.error("There was an error fetching the characters:", error);
    } finally {
      // setCharacterLoading(false); // Set loading to false after fetching (regardless of success or failure)
    }
  };
  const fetchRemoteCharacterInfo = async (
    character_id: any,
    setCharacter: any
  ) => {
    const apiUrl = process.env.REACT_APP_RETRIEVE_CHARACTERS_API;
    if (!apiUrl) {
      throw new Error("API URL not defined in environment variables");
    }
    const request_body = {
      structure: "chat-character",
      character_id: character_id,
    };
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(request_body),
      });

      if (response.ok) {
        const data = await response.json();
        setCharacter(data[0]);
      } else {
        throw new Error(`Server responded with ${response.status}`);
      }
    } catch (error) {
      console.error("There was an error fetching the character", error);
    }
  };

  const fetchCharacterInfo = async () => {
    // Make sure characters are loaded in the context
    if (!characters) {
      console.warn("Chaacters not loaded in context");
      return;
    }
    fetchRemoteCharacterInfo(character_id1, setCharacter1);
    fetchRemoteCharacterInfo(character_id2, setCharacter2);
    setCharactersLoading(false);
  };

  useEffect(() => {
    const uniqueId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    if (sessionId === "") {
      setSessionId(uniqueId);
      console.log("Actual Session ID setting", uniqueId);
    }
    fetchCharacterInfo();
    if (isAuthenticated && user) {
      setUsername(user["https://chat.4wall.ai/username"]);
    } else {
      setUsername("N/A");
    }
    if (botcast_id !== undefined) {
      fetchBotcast();
      setBotcastId(botcast_id);
      botcastIdRef.current = botcast_id;
      setShowInput(false);
    } else {
      setShowInput(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    usernameRef.current = username;
  }, [username]);

  const scrollToBottom = (
    refVariable: RefObject<HTMLDivElement>,
    behavior: any
  ) => {
    if (refVariable.current !== null) {
      refVariable.current.scrollIntoView({
        block: "end",
        inline: "center",
        behavior: behavior,
      });
    }
  };

  const botcastApiUrl = process.env.REACT_APP_BOTCAST_WEBSOCKET;
  if (!botcastApiUrl) {
    throw new Error("API URL not defined in environment variables");
  }
  useEffect(() => {
    const ws = new WebSocket(botcastApiUrl); // API GATEWAY ENDPOINT

    ws.onopen = () => {
      console.log("WebSocket connected");
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        var sender: "char1" | "char2";
        if (data.end) {
          if (data.bot_character === "second") {
            setBotCharacter("first");
            var bot_character = "first";
            sender = "char2";
          } else {
            setBotCharacter("second");
            var bot_character = "second";
            sender = "char1";
          }
          var context_to_send = data.mirror_context; // Fucked up gymnastics here it iz what it iz
          var mirror_context_to_send = data.context; // Pls refactor later
          var response_so_far = data.response_so_far;
          var newMessage = { sender: sender, text: data.curr_response };
          var newUpdatedMessagesForApi = [...messagesRef.current, newMessage];
          setMessage(data.curr_response);
          setBotcastId(data.botcast_id);
          botcastIdRef.current = data.botcast_id;
          if (newUpdatedMessagesForApi.length > 20) {
            setConversationActive(false);
            conversationActiveRef.current = false;
          }
          if (conversationActiveRef.current) {
            const nextMessagePayload = {
              action: "sendMessage",
              message: newMessage.text,
              previous_context: context_to_send,
              mirror_previous_context: mirror_context_to_send,
              session_id: sessionId,
              ui_context: newUpdatedMessagesForApi,
              character_id1: character_id1,
              character_id2: character_id2,
              botcast_id: data.botcast_id,
              topic: topicRef.current,
              bot_character: bot_character,
              username: usernameRef.current,
            };
            // ws?.send(JSON.stringify(nextMessagePayload));
            setTimeout(() => {
              ws?.send(JSON.stringify(nextMessagePayload));
            }, 3000); // Send the next message after a short delay
          }
          return;
        }

        if (data.character_order == "first") {
          sender = "char1";
        } else {
          sender = "char2";
        }
        if (conversationActiveRef.current) {
          setMessages((prev) => {
            if (data.first) {
              return [
                ...prev,
                {
                  sender: sender,
                  text: data.message,
                },
              ];
            } else {
              return prev.map((msg, index) =>
                index === prev.length - 1
                  ? { ...msg, text: msg.text + data.message }
                  : msg
              );
            }
          });
        }
      } catch (e) {
        console.error("Error parsing JSON:", e);
        sendMessage(
          response_so_far,
          context_to_send,
          mirror_context_to_send,
          []
        );
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error: ", error);
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected");
    };
    setWebsocket(ws);
    return () => {
      ws.close();
    };
  }, []);

  const endOfMessagesRef: RefObject<HTMLDivElement> =
    useRef<HTMLDivElement | null>(null);
  const messageBoxRef: RefObject<HTMLDivElement> =
    useRef<HTMLDivElement | null>(null);

  //   useEffect(() => {
  //     scrollToBottom(endOfMessagesRef, "smooth");
  //   }, [messages.length]);

  useEffect(() => {
    messageBoxRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages.length]);

  const sendMessage = (
    messageToSend: string,
    context: PromptStyle[],
    mirror_context: PromptStyle[],
    updatedMessagesForApi: any
  ) => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      console.log("Sending message and starting botcast");
      const messagePayload = {
        action: "sendMessage",
        message: messageToSend,
        previous_context: context, // First go
        mirror_previous_context: mirror_context,
        session_id: sessionId,
        ui_context: updatedMessagesForApi,
        character_id1: character_id1,
        character_id2: character_id2,
        botcast_id: botcastId,
        topic: topicRef.current,
        bot_character: botCharacter,
        username: username,
      };

      // Send the first message
      websocket.send(JSON.stringify(messagePayload));
    } else {
      console.error("WebSocket connection is not established");
    }
  };
  useEffect(() => {
    conversationActiveRef.current = conversationActive;
  }, [conversationActive]);

  const handleTopicSubmit = (topicChosen: string) => {
    if (topicChosen === "") {
      alert("Please enter a topic of discussion");
      return;
    }
    setTopic(topicChosen);
    topicRef.current = topicChosen;
    setShowInput(false);
    setShowTopic(true);
    setConversationActive(true);

    // Vercel analytics
    if (character_id1 && character_id2) {
      track("Start Botcast", {
        character1Name: character1.name,
        character1Id: character_id1,
        character2Name: character2.name,
        character2Id: character_id2,
        topic: topic,
      });
    }
    const init_messages: Message[] = [
      {
        sender: "char1",
        text: `Hi ${character2.name}! Its ${character1.name}. Let's talk about ${topicChosen}.`,
      },
    ];
    setMessages(init_messages);
    const newMessage = { sender: "user", text: message };
    const updatedMessagesForApi = [...messages, newMessage];
    const context: PromptStyle[] = [];
    const mirror_context: PromptStyle[] = [];
    sendMessage(message, context, mirror_context, updatedMessagesForApi);
  };
  const handleSave = () => {
    console.log("Save chat logic to be added");
  };
  const handleDelete = () => {
    console.log("Delete chat logic to be added");
  };
  const firstCharacterMessageStyle = {
    background: `url(${character1.image_url}) no-repeat center center`,
    backgroundSize: "cover",
  };
  const secondCharacterMessageStyle = {
    background: `url(${character2.image_url}) no-repeat center center`,
    backgroundSize: "cover",
  };

  const handleStop = () => {
    setConversationActive(false);
    conversationActiveRef.current = false;
  };
  const handlePrivateSwitch = (isPrivate: boolean) => {
    const apiUrl = process.env.REACT_APP_RETRIEVE_CHARACTERS_API;
    if (!apiUrl) {
      throw new Error("API URL not defined in environment variables");
    }
    setIsPrivateBotcast(isPrivate);
    try {
      fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify({
          structure: "set-botcast-private",
          botcast_id: botcastIdRef.current,
          private: isPrivate,
        }),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Botcast privacy changed");
            showAlert("Botcast privacy changed", "success");
          }
        })
        .catch((error) => {
          console.error("Error setting botcast to private:", error);
          showAlert("Error setting botcast to private", "error");
        });
    } catch (error) {
      console.error("Error setting botcast to private:", error);
      showAlert("Error setting botcast to private", "error");
    }
  };
  const handleSendShareData = () => {
    console.log(character1);
    if (character_id1 && character_id2) {
      track("Share Botcast", {
        character1Name: character1.name,
        character1Id: character_id1,
        character2Name: character2.name,
        character2Id: character_id1,
        botcastId: botcastIdRef.current,
        topic: topicRef.current,
      });
    }
  };
  const conversationActiveRef = useRef(conversationActive);
  conversationActiveRef.current = conversationActive;
  if (isLoading || charactersLoading) {
    return <div>Loading ...</div>;
  }
  return (
    <div className="background">
      {/* <Header /> */}
      <div className="cast-container">
        <div className="cast-header">
          <ArrowBackIosNewRoundedIcon
            className="back-arrow"
            onClick={() => navigate("/")}
            sx={{ margin: "auto 0", cursor: "pointer" }}
          />
          <div className="char first">
            <img
              className="chat-header__chardp"
              src={character1.image_url}
              alt={character1.name}
              title={character1.name} // Tooltip added here
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src =
                  "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
              }}
            />
          </div>
          &#x1F399;
          <div className="char second">
            <img
              className="chat-header__chardp"
              src={character2.image_url}
              alt={character2.name}
              title={character2.name} // Tooltip added here
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src =
                  "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
              }}
            />
          </div>
          {showTopic && botcastIdRef.current != "" && (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={isPrivateBotcast}
                  onChange={(e) => handlePrivateSwitch(e.target.checked)} // Accessing the checked state
                />
              }
              label="Private"
              sx={{ marginLeft: "auto" }}
            />
          )}
          {/* <div className="dropdown"> */}
          {botcastIdRef.current && !conversationActiveRef.current && (
            <BotcastSharePopup
              url={`https://${process.env.REACT_APP_ENVIRONMENT}.4wall.ai/botcast/view/${character_id1}/${character_id2}/${botcastIdRef.current}`}
              sendShareData={() => handleSendShareData()}
            />
          )}
          {/* {userIsOwner && (
              <button
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                }}
              >
                ⋮
              </button>
            )}
            {dropdownOpen && (
              <div className="dropdown-content">
                <p onClick={handleSave}>Save Botcast</p>
                <p onClick={handleDelete}>Delete & Start New Botcast</p>
              </div>
            )} */}
          {/* </div> */}
        </div>
        <div className="topic">
          <div className="cast-input">
            {showInput && (
              <>
                <input
                  type="text"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleTopicSubmit(topic);
                    }
                  }}
                  placeholder="Enter a Topic of Discussion"
                />
                <Button
                  variant="contained"
                  className="start-btn"
                  onClick={() => {
                    handleTopicSubmit(topic);
                  }}
                >
                  START
                </Button>
                {/* <button
                  className="start-btn"
                  onClick={() => handleTopicSubmit(topic)}
                >
                  START
                </button> */}
              </>
            )}

            {showTopic && <h3 className="botcast-topic">{topic}</h3>}
            <div className="stream-utils">
              {/* {!conversationActiveRef.current && <PlayArrowIcon />} */}
              {conversationActiveRef.current && (
                <>
                  {/* <PauseIcon
                      sx={{ height: "2rem", width: "2rem", cursor: "pointer" }}
                    /> */}
                  <Button
                    sx={{
                      backgroundColor: "#FF0000", // Red background color
                      borderRadius: "0.7rem",
                      marginLeft: "1rem",
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.error.dark,
                      },
                    }}
                    variant="contained"
                    className="stop-button"
                    onClick={handleStop}
                  >
                    STOP
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="cast-window">
          {messages.map((message, index) => (
            <div
              key={index}
              className="cast-message"
              style={
                message.sender === "char1"
                  ? firstCharacterMessageStyle
                  : secondCharacterMessageStyle
              }
            >
              <div
                className="cast-message-content"
                ref={messageBoxRef}
                // style={
                //   message.sender === "char1"
                //     ? firstBubbleStyle
                //     : secondBubbleStyle
                // }
              >
                <div className="cast-message-header">
                  {message.sender == "char1" ? (
                    <h4>{character1.name}</h4>
                  ) : (
                    <h4>{character2.name}</h4>
                  )}
                </div>

                <p>{message.text}</p>

                <div className="cast-feedback-buttons">
                  <ThumbUp
                    className={`like-button ${
                      activeButtons[index] === "like" ? "active" : ""
                    }`}
                    //   onClick={() => handleLikes(true, index)}
                  ></ThumbUp>
                  <ThumbDown
                    className={`dislike-button ${
                      activeButtons[index] === "dislike" ? "active" : ""
                    }`}
                    //   onClick={() => handleLikes(false, index)}
                  ></ThumbDown>
                </div>
              </div>
            </div>
          ))}
          <div
            className="shadow-scroll-container"
            style={{ height: 1 + "px" }}
            ref={endOfMessagesRef}
          ></div>
        </div>

        <Snackbar
          open={alertInfo.open}
          autoHideDuration={6000}
          onClose={closeAlert}
        >
          <Alert
            onClose={closeAlert}
            severity={alertInfo.severity}
            sx={{ width: "100%" }}
          >
            {alertInfo.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default Botcast;
