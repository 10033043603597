import React from "react";
import { SocialIcon } from "react-social-icons";
import { RiTwitterXFill } from "react-icons/ri";
import Login from "../Auth/Login";
import SignUp from "../Auth/SignUp";
import "./Misc.css";
import { Margin } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";

interface WelcomeProps {
  setShowWelcome: (show: boolean) => void;
}

function Welcome({ setShowWelcome }: WelcomeProps) {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="popup-overlay">
      <div className="welcome-popup-content">
        <h1>
          Welcome to 4Wall AI{" "}
          <span role="img" aria-label="wave">
            👋
          </span>
        </h1>
        <p>
          Chat with your favorite characters, or watch them interact through a
          BOTCAST!
        </p>
        <div className="info">
          <p>
            <span role="img" aria-label="clown">
              ⚠️
            </span>
            Remember: Everything characters say is fictional! Don't take them
            seriously.
          </p>
          <p>🔥 Get ready for an epic adventure. </p>
          <p>
            ✨ Coming Soon: Role-playing games and group chats with your friends
            and AI.
          </p>
        </div>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.5rem",
            color: "var(--primary-text-color)",
          }}
        >
          Follow us for updates:{" "}
          <SocialIcon
            url="https://discord.gg/EUebsQR5KE"
            network="discord"
            style={styles.socialIconStyle}
            // style={{ "2rem" }}
          />
          <SocialIcon
            url="https://www.reddit.com/r/4WallAI/"
            style={styles.socialIconStyle}
          />
          <SocialIcon
            url="https://x.com/4WallAI_"
            className="social-icon"
            style={styles.socialIconStyle}
          />
        </p>
        <button
          className="accept-btn"
          onClick={() => {
            // setShowWelcome(false);
            // localStorage.setItem("showedWelcome", "true");
            loginWithRedirect();
          }}
        >
          Continue
        </button>
        {/* <div style={styles.loginButtonSection}>
          <Login redirectUrl="/" />
          <SignUp redirectUrl="/" />
        </div> */}
      </div>
    </div>
  );
}

const styles = {
  socialIconStyle: {
    height: 50,
    width: 50,
    margin: "0 0.5rem",
  },
  loginButtonSection: {
    display: "flex",
    margin: "0 10rem",
  },
};

export default Welcome;
