import React, {
  useState,
  useEffect,
  FC,
  useContext,
  forwardRef,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import Welcome from "./Misc/Welcome";
import Footer from "./Footer";
import Header from "./Header";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../contexts/PlatformContext";
import { CSSTransition } from "react-transition-group";
import { CircularProgress } from "@mui/material";
import { Character, Chat } from "../types";
import MultiChatPopup from "./Misc/MultiChatPopup";
import FeaturedSwiper from "./Landing/FeaturedSwiper";
import Continue from "./Landing/Continue";
import Explore from "./Landing/Explore";
import useRequest from "../apis/useRequest";
import Button from "@mui/material/Button";
import { FeaturedLists } from "../types";
import Feed from "./Feed/Feed";
import NewsCarousel from "./Landing/NewsCarousel";
import Divider from "@mui/material/Divider";
import { Media } from "./Feed/Feed";
export interface CharacterGroup {
  character: Chat;
  chats: Chat[];
}

const HomePage: FC = () => {
  const makeRequest = useRequest();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { characters, userAssets, showChats, featuredLists } = context;
  const [continueCharacters, setContinueCharacters] = useState<
    CharacterGroup[]
  >([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isDynamicTheme, setIsDynamicTheme] = useState<boolean>(false);
  const [selectedCharacterMultiChats, setSelectedCharacterMultiChats] =
    useState<Chat[]>([]);
  const [showWelcome, setShowWelcome] = useState<boolean>(true);
  const [showMultiChatPopup, setShowMultiChatPopup] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("featured");
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const showedWelcome = localStorage.getItem("showedWelcome");
    if (!showedWelcome) {
      setShowWelcome(true);
    }
  }, []);

  const aggregateChats = (chats: Chat[]) => {
    const groups: { [characterId: string]: CharacterGroup } = {};

    chats.forEach((chat) => {
      if (!groups[chat.character_id]) {
        groups[chat.character_id] = { character: chat, chats: [] };
      } else {
        groups[chat.character_id].character = chat;
      }
      groups[chat.character_id].chats.push(chat);
    });

    Object.values(groups).forEach((group) =>
      group.chats.sort(
        (a, b) =>
          new Date(b.last_interaction).getTime() -
          new Date(a.last_interaction).getTime()
      )
    );
    setContinueCharacters(Object.values(groups));
  };

  useEffect(() => {
    aggregateChats(userAssets.chats);
  }, [isAuthenticated]);

  const handleCharacterSelect = (character: Character) => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `/chat/${character.character_id}`,
        },
      });
      return;
    }
    navigate(`/chat/${character.character_id}`);
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const media: Media[] = [
    { id: 1, url: "/darkalt.png", type: "image" },
    { id: 2, url: "/bgnew.jpeg", type: "image" },
    { id: 3, url: "darksunset.png", type: "image" },
    { id: 4, url: "lightforest.png", type: "image" },
  ];

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div className="homepage-container">
          {!isMobile && <NewsCarousel />}
          {!isAuthenticated && showWelcome && (
            <Welcome setShowWelcome={setShowWelcome} />
          )}
          <div className="featured-list-header">
            {!isMobile && (
              <>
                {" "}
                <h1>
                  <span>CHARACTERS</span>
                </h1>
                <label
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    textShadow: "2px 2px 4px #000000",
                  }}
                ></label>
              </>
            )}

            <div className="landing-tab-container">
              <Button
                onClick={() => handleTabChange("featured")}
                className={`custom-tab-button ${
                  activeTab === "featured" ? "active" : "inactive"
                }`}
              >
                Featured 🔥
              </Button>
              <Button
                onClick={() => handleTabChange("explore")}
                className={`custom-tab-button ${
                  activeTab === "explore" ? "active" : "inactive"
                }`}
              >
                Explore 🌐
              </Button>
            </div>
          </div>

          {activeTab === "featured" &&
            Object.entries(featuredLists).map(([key, value]) => (
              <FeaturedSwiper
                key={key}
                featuredCharacters={value}
                name={
                  key === "moviesTV"
                    ? "MOVIES & TV"
                    : key === "isekaiRPG"
                      ? "ISEKAI"
                      : key.toUpperCase()
                }
                loaded={value.length > 0}
                handleCharacterSelect={handleCharacterSelect}
              />
            ))}
          {activeTab === "explore" && (
            <Explore
              handleCharacterSelect={handleCharacterSelect}
              isDynamicTheme={isDynamicTheme}
            />
          )}
          <CSSTransition
            in={showMultiChatPopup !== null}
            timeout={300}
            classNames="popup"
            unmountOnExit
          >
            {showMultiChatPopup ? (
              <MultiChatPopup
                open={showMultiChatPopup}
                chats={selectedCharacterMultiChats}
                onClose={() => setShowMultiChatPopup(false)}
              />
            ) : (
              <></>
            )}
          </CSSTransition>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
