export const chubInfo = `<p>You can upload a JSON character definiton from chub ai. All possible fields will be automatically populated, and all the personality sliders will be set to 50.</p>`;
export const descriptionInfo = `<p>Dive deep into your character's essence. Bring out their quirks, personality, habits, and the subtle details that make them truly unique. The more descriptive and nuanced your portrayal, the more your character will come to life. 
Be as specific or as general as you like—the richer the details, the more vivid and lifelike your character becomes!</p>
<p>Remember, a well-crafted character description is more than just words—it's the foundation upon which all engaging conversations are built!</p>`;
export const greetingInfo = `<p>Your character will use this message to introduce themselves and spark a conversation.</p>`;
export const tagsInfo = `<p>Choose one or more tags for your character.</p>`;
export const exampleConvoInfo = `<p>You can also provide optional example interactions that reveal key aspects of your character's persona and highlight their unique voice. For the model to understand these correctly, 
                    please use the following format:</p>
                    <br>{{user}}: Hi {{character}}, how are you doing today! <br> 
                    {{character}}: Welcome, to the netherwold {{user}}. are you ready to go on an adventure?
                    </p>`;
export const personalizeInfo = `<p>Personalize your chat by customizing the message bubbles. Choose colors that match your character's style or your own preference to make your conversations pop! Adjust the following:</p>
<ul>
  <li><strong>Character Bubble Color:</strong> Pick a color for your character’s message bubbles.</li>
  <li><strong>Character Text Color:</strong> Select a color for the text within your character’s message bubbles.</li>
  <li><strong>User Bubble Color:</strong> Set a color for your own message bubbles as the user.</li>
  <li><strong>User Text Color:</strong> Choose a color for the text within your message bubbles.</li>
</ul>
<p>Play around with the palette to bring a unique visual flair to your chat sessions. Click on the color squares to open a color picker and start creating your personalized chat theme!</p>`;
