import { RequestDefinition, RequestMethod, RequestName } from "./types";

export function getUserAssets(
  username: string,
  other: string = "false"
): RequestDefinition {
  return {
    name: RequestName.GET_USER_ASSETS,
    path: `/assets/${username}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
    queryParams: { other: other },
  };
}

export function updatePersona(persona_id: string): RequestDefinition {
  return {
    name: RequestName.UPDATE_PERSONA,
    path: `/persona/${persona_id}`,
    method: RequestMethod.PUT,
    shouldChangeContext: false,
  };
}

export function createPersona(): RequestDefinition {
  return {
    name: RequestName.CREATE_PERSONA,
    path: `/persona`,
    method: RequestMethod.POST,
    shouldChangeContext: false,
  };
}

export function getPersona(persona_id: string): RequestDefinition {
  return {
    name: RequestName.GET_PERSONA,
    path: `/persona/${persona_id}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function getChatMessages(
  chat_id: string | undefined
): RequestDefinition {
  return {
    name: RequestName.GET_CHAT_MESSAGES,
    path: `/chat/${chat_id}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function getFeaturedCharacters(): RequestDefinition {
  return {
    name: RequestName.GET_FEATURED_CHARACTERS,
    path: `/landing/characters`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function updateMessage(
  message_id: string,
  chat_id: string | undefined,
  operation: "edit" | "regen"
): RequestDefinition {
  return {
    name: RequestName.UPDATE_MESSAGE,
    path: `/message/${chat_id}/${message_id}`,
    method: RequestMethod.PUT,
    shouldChangeContext: false,
    queryParams: { operation: operation },
  };
}

export function deleteMessage(
  message_id: string,
  chat_id: string | undefined
): RequestDefinition {
  return {
    name: RequestName.DELETE_MESSAGE,
    path: `/message/${chat_id}/${message_id}`,
    method: RequestMethod.DELETE,
    shouldChangeContext: false,
  };
}

export function getCharacter(character_id: string): RequestDefinition {
  return {
    name: RequestName.GET_CHARACTER,
    path: `/character/${character_id}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}
