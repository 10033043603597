import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button, { ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { purple, blue } from "@mui/material/colors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { CreatedCharactersProps } from "../ProfilePage/CreatedCharacters";
import { Character, UserAssets } from "../../types";
import CharacterImage from "../Misc/CharacterImage";
import LockIcon from "@mui/icons-material/Lock";
import "./Character.css";

export interface CharacterProfileProps {
  character_id: string;
  name: string;
  interactions: number;
  num_saves: number;
  isOther?: boolean;
  image_url: string;
  is_public: boolean;
  setCharacters: React.Dispatch<React.SetStateAction<CreatedCharactersProps[]>>;
  setUserAssets: React.Dispatch<React.SetStateAction<UserAssets>>;
}

export const CharacterProfile: React.FC<CharacterProfileProps> = ({
  character_id,
  name,
  interactions,
  num_saves,
  isOther,
  is_public,
  image_url,
  setCharacters,
  setUserAssets,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const StartNewButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    borderRadius: "2rem",
    margin: "0.5rem",
    fontFamily: "Pixelify Sans",
    "&:hover": {
      backgroundColor: blue[700],
    },
  }));
  const navigate = useNavigate();
  const handleDelete = () => {
    console.log("Deleting character with ID:", character_id);
    const deleteChat = async () => {
      const request_body = {
        item: "character",
        character_id: character_id,
      };

      try {
        const response = await fetch(
          "https://bwms5czial4cghxgl2ysswzrra0ydlgs.lambda-url.us-east-1.on.aws/",
          {
            method: "POST",
            body: JSON.stringify(request_body),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCharacters((prevChars) =>
            prevChars.filter((char) => char.character_id !== character_id)
          );
          setUserAssets((prevUserAssets) => ({
            ...prevUserAssets,
            characters: prevUserAssets.characters.filter(
              (character) => character.character_id !== character_id
            ),
          }));
        } else {
          throw new Error(`Server responded with ${response.status}`);
        }
      } catch (error) {
        console.error("There was an error fetching the characters:", error);
      }
    };

    deleteChat();
    setOpen(false);
  };
  return (
    <div className="profile-character-card fastFadeInUp">
      <img
        src={image_url}
        alt={name}
        onError={(e) => {
          const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
          target.onerror = null; // prevents looping
          target.src =
            "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
        }}
        className="profile-character-image"
      />

      <div className="profile-character-info">
        <h3>
          {name}{" "}
          {String(is_public).toLowerCase() === "false" ? <LockIcon /> : null}
        </h3>

        <p>
          Interactions <span>{interactions}</span>
        </p>
        {/* <p>
          Favorites <span>{num_saves}</span>
        </p> */}
        <StartNewButton
          variant="contained"
          color="primary"
          onClick={() => navigate(`/chat/${character_id}`)}
        >
          Chat
        </StartNewButton>
      </div>

      {!isOther && (
        <div>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => navigate(`/create/${character_id}`)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="error"
            onClick={handleClickOpen}
          >
            <DeleteIcon />
          </IconButton>
          <Dialog className="delete-popup" open={open} onClose={handleClose}>
            <DialogTitle>{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText color={"var(--primary-text-color"}>
                Are you sure you want to delete this character?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleDelete} color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};
