import React, { useEffect, useState } from "react";

interface CharacterImageProps {
  image_url: string;
  name: string;
  className?: string;
  onLoad?: () => void;
}

const CharacterImage: React.FC<CharacterImageProps> = React.memo(
  ({ image_url, name, className }) => {
    const [currentImageUrl, setCurrentImageUrl] = useState(image_url);
    const [errorCount, setErrorCount] = useState(0);
    const defaultImageUrl =
      "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
    const handleError = () => {
      setErrorCount((prevCount) => prevCount + 1);
      if (errorCount === 0) {
        // First error: try with '.jpg' extension
        setCurrentImageUrl(image_url + ".jpg");
      } else if (errorCount === 1) {
        // Second error: fall back to the default image
        setCurrentImageUrl(defaultImageUrl);
      }
    };
    useEffect(() => {
      setCurrentImageUrl(image_url);
      setErrorCount(0);
    }, [image_url]);
    return (
      <img
        src={currentImageUrl}
        onError={handleError}
        alt={name}
        className={
          className + " fadeInUp" || "default-character-image-class fadeInUp"
        }
      />
    );
  }
);

export default CharacterImage;
